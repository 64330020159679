import { nav } from "../_nav";
import { Buffer } from "buffer";
import moment from "moment";
import Cookies from "js-cookie";
import "moment-timezone";
import {
  BASIC,
  DECORATED,
  LINE,
  PICTURE,
  SVG,
  TABLE,
  TEXT,
  UPLOAD,
  VIDEO,
  YOUTUBE,
  GROUP_WIDGET,
  SHAPE,
  PAGE_MARGIN_LEFT_RIGHT,
  PAGE_MARGIN_TOP_BOTTOM,
  CROP_TO_SHAPE_WIDTH,
  CROP_TO_SHAPE_HEIGHT,
  MAP,
  COLLAGE,
  PAGE_MARGIN_TOP_BOTTOM_WITH_TIMELINE,
  STOCK,
  BRAND,
  UPLOAD_VIDEO,
  PAGE_MARGIN_TOP_BOTTOM_NON_VIDEO_TYPE_USER,
} from "../constants/editor";
import { TEMPLATE_PROVIDER_SYSTEM as SYSTEM } from "../constants/template";
import { LENGTH_UNITS_CONVERSION_FACTORS } from "../constants/pageSizeModal";

const findRouteName = url => {
  if (url.split("/").length <= 2) {
    let route = reverseLookup(nav.items, url);
    return route.name;
  }
  const paths = [];
  url.split("/").reduce((prev, curr, index) => {
    paths[index] = `${prev}/${curr}`;
    return paths[index];
  });
  // let route = reverseLookup(nav.items,url);
  let route = paths.reduce((prev, curr) => {
    if (prev.trim() !== "") {
      let route = reverseLookup(nav.items, prev);
      route = reverseLookup(route.children, url);
      return route;
    }

    let route = reverseLookup(nav.items, curr);
    return route;
  });

  return route.name;
};

const reverseLookup = (nav, match) => {
  return nav.find(n => {
    return n.url === match;
  });
};

const getRandomString = length => {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const getUnScaledValue = (value, zoomFactor) => {
  let targetVal = parseFloat(value) / (zoomFactor / 100);
  return targetVal;
};

const getZoomedValue = (value, zoomFactor) => {
  let targetVal = parseFloat(value) * (zoomFactor / 100);
  return targetVal;
};

const isClickedOutside = (target, selectors) => selectors.every(selector => !target.closest(selector));

const placeholderTextAnimation = {
  meta: {},
  getDelay: (min = 50, max = 90) => {
    return Math.round(Math.random() * (max - min + 1) + min);
  },
  start: (inputElm, placeholder, meta = placeholderTextAnimation.meta) => {
    Object.assign(meta, {
      char: 0,
      type: false,
      target: inputElm,
      txt: placeholder,
      txtLen: placeholder.length,
    });
    placeholderTextAnimation.animate();
  },
  animate: (meta = placeholderTextAnimation.meta) => {
    let delay = placeholderTextAnimation.getDelay();
    let timeout = setTimeout(() => {
      meta.char++;
      meta.type = meta.txt.substring(0, meta.char);
      meta.target.setAttribute("placeholder", meta.type);
      if (meta.char < meta.txtLen) placeholderTextAnimation.animate();
      if (meta.char === meta.txtLen) clearTimeout(timeout);
    }, delay);
  },
};

function debounceTrailing(func, wait) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), wait);
  };
}

const dateTimeDataset = {
  timeformats: {
    "12 Hours": "12 Hours",
    "24 Hours": "24 Hours",
  },

  dateformats: {
    "MM-DD-YYYY": "Month-Day-Year",
    "DD-MM-YYYY": "Day-Month-Year",
  },
};

const defaultAccountData = {
  time_format: "12 Hours",
  date_format: "MM-DD-YYYY",
};

const getSiblings = elem => {
  let siblings = [];
  let sibling = elem.parentNode.firstChild;
  for (; sibling; sibling = sibling.nextSibling) if (sibling.nodeType == 1 && sibling != elem) siblings.push(sibling);
  return siblings;
};

const preventFormSubmitOnEnter = e => {
  if (e.which === 13) e.preventDefault();
};

const hexToRGB = hex => {
  let result;
  if (hex === "transparent") {
    result = "transparent";
  } else {
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let r = parseInt(result?.[1], 16),
      g = parseInt(result?.[2], 16),
      b = parseInt(result?.[3], 16);

    result = `rgb(${r}, ${g}, ${b})`;
  }

  return result;
};

const RGBToHex = rgb => {
  rgb =
    rgb === "transparent"
      ? "transparent"
      : rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

  return rgb && rgb.length === 4
    ? "#" +
        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
    : rgb === "transparent"
    ? "transparent"
    : "";
};

const changeWidgetColorAccordingBackground = bgColor => {
  let match;

  if (bgColor && bgColor !== "transparent") {
    match = bgColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
  } else {
    let transparent = "rgb(255, 255, 255)";
    match = transparent.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
  }

  let range = Math.round((parseInt(match[1]) * 299 + parseInt(match[2]) * 587 + parseInt(match[3]) * 114) / 1000);

  if (range > 125) {
    return "rgb(0, 0, 0)"; // for light range return black
  } else {
    return "rgb(255, 255, 255)"; // for dark range return white
  }
};

const changeBucketColorAccordingBackground = tooltipPalletColor => {
  let type = typeof tooltipPalletColor;
  let match,
    range = [];

  //For single color, color bucket
  if (type === "string" || (type === "object" && tooltipPalletColor.length === 1)) {
    if (
      tooltipPalletColor &&
      ((type === "string" && tooltipPalletColor !== "transparent") ||
        (type === "object" && tooltipPalletColor[0] !== "transparent")) &&
      tooltipPalletColor !== "Mixed Colors"
    ) {
      let palletColor = type === "string" ? tooltipPalletColor : tooltipPalletColor[0];
      match = palletColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    } else {
      let transparent = "rgb(255, 255, 255)";
      match = transparent.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    }

    range.push(
      Math.round((parseInt(match?.[1]) * 299 + parseInt(match?.[2]) * 587 + parseInt(match?.[3]) * 114) / 1000)
    );
  } else {
    //For multi color, color bucket
    let limit = tooltipPalletColor.length >= 4 ? 3 : tooltipPalletColor.length - 1;

    for (var i = 0; i < limit; i++) {
      if (tooltipPalletColor[i] !== "transparent") {
        match = tooltipPalletColor[i].match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        range.push(
          Math.round((parseInt(match?.[1]) * 299 + parseInt(match?.[2]) * 587 + parseInt(match?.[3]) * 114) / 1000)
        );
      }
    }
  }

  if (range.every(value => value > 125)) {
    return "light"; // for light range return black
  } else {
    return "dark"; // for dark range return white
  }
};

const getCssTextObjToString = (cssTextObj, returnStr = "") => {
  if (cssTextObj) {
    Object.keys(cssTextObj).forEach(key => {
      returnStr += `${key}: ${cssTextObj[key]}; `;
    });
  }

  return returnStr.trim();
};

const getClassListToString = (classList, returnStr = "") => {
  if (classList) {
    classList.forEach(cls => (returnStr += `${cls} `));
  }
  return returnStr.trim();
};

const getFileType = fileName => {
  const validFileTypes = ["jpeg", "jpg", "png", "gif", "svg", "mp4", "jfif"];
  const invalidFileTypes = ["", "h1", "h2", "h3", "h4", "h5", "h6", "normal"];
  const fileType = fileName.split(".").pop().split("?")[0].toLowerCase();

  // general
  if (validFileTypes.includes(fileType)) {
    return fileType;
  }
  // invalid
  else if (invalidFileTypes.includes(fileType)) {
    return "";
  }
  // general (generally for # included videos with trim applied)
  else if (fileName.split("#t").length > 0) {
    const format = fileName.split("#t")[0].split(".").pop().split("?")[0].toLowerCase();
    if (validFileTypes.includes(format)) return format;
    else return "";
  }
  // for unsplash and iframe (i.e. typeform)
  else {
    return fileName.split("fm=")[1]?.split("&")[0] ?? "";
  }
};

const validateNumberInput = event => {
  if (
    [8, 13, 27, 37, 39, 46, 110, 190].includes(event.which) ||
    (event.which >= 48 && event.which <= 57) ||
    (event.which >= 96 && event.which <= 105) ||
    ([65, 67, 86, 88].includes(event.which) && event.ctrlKey === true)
  ) {
    // backspace, enter, escape, arrows, delete, numbers, numpad number, Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

const formatTemplatePageSizes = (availableTemplatePageSizes, availableTemplateGroups) => {
  let pageSizeResources = {
    metadata: {
      availablePageSizes: {},
      pageSizeIndexes: {},
    },
  };

  availableTemplatePageSizes?.forEach(item => {
    let formatedType = camelCaseToDash(item.type);
    let filteredGrp = availableTemplateGroups?.template_groups?.find(grp => grp.slug === formatedType);

    if (filteredGrp) {
      pageSizeResources.metadata.availablePageSizes[item.type] = filteredGrp?.type?.map(elem => ({
        label: elem.name,
        value: elem.slug,
      }));
    } else {
      pageSizeResources.metadata.availablePageSizes[item.type] = item.page.map(elem => ({
        label: elem.label,
        value: elem.value,
      }));
    }

    item.page.forEach(page => {
      if ("custom" != page.value) {
        pageSizeResources.metadata.pageSizeIndexes[page.value] = {
          width: page.width,
          height: page.height,
        };
      }
    });
  });

  return pageSizeResources;
};

const toProperCase = type => {
  return type
    .replace(/-|_/g, " ")
    .split(" ")
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
};

const getTimezoneInfo = () => {
  let timeZone = moment.tz.guess();
  let time = new Date();
  let code = moment().tz(timeZone).format("z");
  let y = time.toString().split(" ")[5];
  let gmtValue = y.substring(0, 6) + ":" + y.substring(6);
  gmtValue = ["GMT+00:00", "GMT±00:00"].includes(gmtValue) ? "GMT±00:00" : gmtValue;
  return {
    code,
    gmtValue,
  };
};

const clearAllTextFields = () => {
  document.querySelectorAll("input[type=text]").forEach(field => (field.value = ""));
};

const getStringToValidJSON = str => {
  try {
    return JSON.parse(str);
  } catch {
    return;
  }
};

const stopAllResourceBuffering = (htmlElem, selector) => {
  // works for audio, video and image (disabled because this method is currently not working and additionally causing problem for video trim, play request was inntterupted by pause due to src = '')
  // htmlElem?.querySelectorAll(selector)?.forEach(resource => (resource.src = ""));
  // stop all resources like images, audios, videos, scripts and ongoing API calls (disabled because causing problem with API calls)
  // window.stop();
};

// for preview & present
const prepareVideoHtml = () => {
  document.querySelectorAll(`div[data-widget='MEDIA']`)?.forEach(widget => {
    let videoSource = widget.getAttribute("data-source");
    let videoKey = widget.getAttribute("data-key");
    let videoStyle = widget.querySelector(".dhp-widget-inner")?.style.cssText;
    let videoHtml = "";
    if (YOUTUBE === videoSource && videoStyle) {
      videoHtml = `<iframe width='100%' height='100%' frameborder='0' allowFullScreen src='https://www.youtube.com/embed/${videoKey}?autoplay=false' style='${videoStyle}'></iframe>`;
      widget.innerHTML = videoHtml;
    }
  });
};

// for preview & present
const alignSvg = () => {
  document.querySelectorAll("div[data-widget='LINE-SHAPE']").forEach(widget => {
    widget.firstChild.style.display = "block";
  });
};

// for preview & present
const displayAbstractShapes = () => {
  document.querySelectorAll("div[data-category='Abstract']").forEach(widget => {
    if (widget.style.height === "0px") widget.style.height = "auto";
  });
};

const getCssTextObj = (cssText, returnStr = false, returnObj = {}) => {
  cssText.split(";")?.forEach(e => {
    if (e.trim() !== "") {
      const [first, ...rest] = e.trim().split(":");
      const key = first.trim();
      const val = rest.join(":").trim();

      if (key !== "" && val !== "") {
        returnObj[key] = val;
      }
    }
  });

  if (returnStr) {
    return getCssTextObjToString(returnObj);
  } else {
    return returnObj;
  }
};

const getCssTransformObj = ({
  translateX,
  translateY,
  scaleX,
  scaleY,
  rotateAngle,
  transform,
  exclude,
  returnObj = false,
  returnStr = false,
  returnHybrid = false,
}) => {
  if (translateX || translateY || scaleX || scaleY || rotateAngle || transform) {
    let transformObj = {
      translate: {
        x: translateX || "0px",
        y: translateY || "0px",
      },
      scale: {
        x: scaleX || 1,
        y: scaleY || 1,
      },
      rotate: {
        theta: rotateAngle || "0deg",
      },
    };
    if (transform) {
      Object.keys(transformObj).forEach(e => {
        if (transform.includes(e)) {
          if (e === "translate") {
            let prop = transform.split(e)[1].split(")")[0].split("(")[1].split(",");
            let x = prop[0].trim();
            let y = prop[1]?.trim() ?? "0px";
            transformObj[e] = {
              x: translateX || x,
              y: translateY || y,
            };
          }
          if (e === "scale") {
            let prop = transform.split(e)[1].split(")")[0].split("(")[1].split(",");
            let x = prop[0].trim();
            let y = prop[1]?.trim() ?? x;
            transformObj[e] = {
              x: scaleX || x,
              y: scaleY || y,
            };
          }
          if (e === "rotate") {
            let prop = transform.split(e)[1].split(")")[0].split("(");
            let theta = prop[1].trim();
            transformObj[e] = {
              theta: rotateAngle || theta,
            };
          }
        }
      });
    }
    returnObj = transformObj;

    if (exclude?.length > 0) {
      Object.keys(returnObj).forEach(e => {
        if (exclude.includes(e)) delete returnObj[e];
      });
    }
  }

  if (returnHybrid) {
    return {
      ...returnObj,
      transform: getCssTransformObjToString({ transformObj: returnObj }),
    };
  } else if (returnStr) {
    return getCssTransformObjToString({ transformObj: returnObj });
  } else {
    return returnObj;
  }
};

const getCssTransformObjToString = ({ transformObj, exclude, returnStr = "" }) => {
  if (transformObj) {
    let { translate, scale, rotate } = { ...transformObj };

    if (translate && !exclude?.includes("translate")) returnStr += `translate(${translate.x},${translate.y}) `;
    if (scale && !exclude?.includes("scale")) returnStr += `scale(${scale.x},${scale.y}) `;
    if (rotate && !exclude?.includes("rotate")) returnStr += `rotate(${rotate.theta})`;
  }
  return returnStr.trim();
};

const getActualDomRect = elem => {
  return {
    width: elem.offsetWidth,
    height: elem.offsetHeight,
    left: elem.offsetLeft,
    top: elem.offsetTop,
    right: elem.offsetLeft + elem.offsetWidth,
    bottom: elem.offsetTop + elem.offsetHeight,
    x: elem.offsetLeft,
    y: elem.offsetTop,
  };
};

const setMultiAttrs = (elem, attrs) => {
  for (let key in attrs) {
    elem.setAttribute(key, attrs[key]);
  }
};

const camelCaseToDash = str => {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

const dashToCamelCase = str => {
  return str.replace(/-([a-z])/g, g => {
    return g[1].toUpperCase();
  });
};

const dispatchEvent = ({ eventType, eventName, htmlElem = document }) => {
  let newEvent;
  let options = { bubbles: true, cancelable: true };

  switch (eventType) {
    case "clipboard":
      newEvent = new ClipboardEvent(eventName, options);
      break;

    default:
      newEvent = new Event(eventName, options);
      break;
  }
  htmlElem.dispatchEvent(newEvent);
};

const searchNodeByPoint = ({ className, x, y, exclude, childClass, result = false, childNode = false }) => {
  let nodes = document.elementsFromPoint(x, y);
  let count = nodes.length;

  for (let i = 0; i < count; i++) {
    if (nodes[i].classList.contains(className)) {
      result = nodes[i];
      if (exclude instanceof Element && result.isEqualNode(exclude)) result = false;
      break;
    }
    if (childClass && [...nodes[i].classList].some(e => childClass.includes(e))) {
      childNode = nodes[i];
    }
  }

  return {
    node: result,
    childNode,
  };
};

const getSvgContentFromUrl = (url, getTrimmedSVG = true) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${url}`, false);
    xhr.overrideMimeType("image/svg+xml");
    xhr.onload = async () => {
      if (getTrimmedSVG) resolve(await processSVG(xhr.responseXML.documentElement));
      else resolve(xhr.responseXML.documentElement);
    };
    xhr.onerror = reject;
    xhr.send("");
  });
};

const invisibleElems = ["defs", "g", "foreignObject", "svg", "style", "title", "desc"];

const processSVG = async svgElement => {
  try {
    if (!svgElement || svgElement.tagName !== "svg") {
      console.error("Invalid SVG element.");
      return svgElement; // Return original content if invalid SVG element
    }

    svgElement.removeAttribute("viewBox");

    // Create a sandbox div to hold the SVG
    const sandbox = document.createElement("div");
    sandbox.style.visibility = "hidden";
    sandbox.style.position = "absolute";
    sandbox.style.top = "0";
    sandbox.style.left = "0";
    sandbox.appendChild(svgElement.cloneNode(true));
    document.body.appendChild(sandbox);

    // await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust as needed for timing

    const flattenedElements = Array.from(sandbox.querySelectorAll("*"));

    let coords = {
      top: Infinity,
      left: Infinity,
      bottom: -Infinity,
      right: -Infinity,
    };

    flattenedElements.forEach(elem => {
      if (invisibleElems.includes(elem.tagName.toLowerCase())) {
        return;
      }

      const boundingBox = elem.getBoundingClientRect();
      if (boundingBox.width === 0 && boundingBox.height === 0) {
        return;
      }

      const computedStyle = window.getComputedStyle(elem);
      const strokeWidth = parseFloat(computedStyle.strokeWidth) || 0;

      const top = boundingBox.top - strokeWidth / 2;
      const left = boundingBox.left - strokeWidth / 2;
      const bottom = boundingBox.bottom + strokeWidth / 2;
      const right = boundingBox.right + strokeWidth / 2;

      if (top < coords.top) coords.top = top;
      if (left < coords.left) coords.left = left;
      if (bottom > coords.bottom) coords.bottom = bottom;
      if (right > coords.right) coords.right = right;
    });

    const viewBox = `${coords.left.toFixed(2)} ${coords.top.toFixed(2)} ${(coords.right - coords.left).toFixed(2)} ${(
      coords.bottom - coords.top
    ).toFixed(2)}`;
    svgElement.setAttribute("viewBox", viewBox);

    // Clean up the sandbox
    document.body.removeChild(sandbox);

    return svgElement;
  } catch (error) {
    console.error("Error processing SVG:", error);
    return svgElement; // Return original content in case of error
  }
};

const getImgDimensionFromUrl = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () =>
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    img.onerror = reject;
  }).catch(() => {
    return {
      reject: true,
    };
  });
};

const getTemplateInfo = template => {
  return {
    document_name: template.name ?? "",
    thumb: template.thumb ?? "",
    template_provider: SYSTEM,
    source_template: template.id ?? "",
    template_type_id: template.template_type ?? "",
    category: template.category ?? "",
    group: template.group ?? "",
    ref: template.ref ?? null,
  };
};

const imagesLoaded = async images => {
  const promises = [];
  let totalCount = images.length;
  let resolvedCount = 0;

  images.forEach(image => {
    promises.push(
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        if (img.complete) resolve(resolvedCount++);
        else {
          img.onload = () => resolve(resolvedCount++);
          img.onerror = () => reject(resolvedCount++);
        }
      })
    );
  });

  return Promise.all(promises).then(() => {
    if (totalCount === resolvedCount) return true;
    else return false;
  });
};

// load all db fonts
const loadAllDBFonts = fonts => {
  fonts.forEach(fontData => {
    let preloadHtml, cssText;
    let link = document.createElement("link");
    link.href = fontData.value;
    link.rel = "stylesheet";

    if (!document.querySelector('link[href="' + link.href + '"]')) {
      document.getElementsByTagName("head")[0].appendChild(link);

      if (fontData.normal_weight) {
        cssText = {
          "font-family": fontData.name,
          "font-weight": fontData.normal_weight,
        };

        preloadHtml = document.createElement("span");
        preloadHtml.style.cssText = getCssTextObjToString(cssText);
        preloadHtml.innerText = "Double click to edit";

        document.querySelector("section.preload-wrap").append(preloadHtml);
      }
      if (fontData.normal_italic_weight) {
        cssText = {
          "font-family": fontData.name,
          "font-weight": fontData.normal_italic_weight,
          "font-style": "italic",
        };

        preloadHtml = document.createElement("span");
        preloadHtml.style.cssText = getCssTextObjToString(cssText);
        preloadHtml.innerText = "Double click to edit";

        document.querySelector("section.preload-wrap").append(preloadHtml);
      }
      if (fontData.bold_weight) {
        cssText = {
          "font-family": fontData.name,
          "font-weight": fontData.bold_weight,
        };

        preloadHtml = document.createElement("span");
        preloadHtml.style.cssText = getCssTextObjToString(cssText);
        preloadHtml.innerText = "Double click to edit";

        document.querySelector("section.preload-wrap").append(preloadHtml);
      }
      if (fontData.bold_italic_weight) {
        cssText = {
          "font-family": fontData.name,
          "font-weight": fontData.bold_italic_weight,
          "font-style": "italic",
        };

        preloadHtml = document.createElement("span");
        preloadHtml.style.cssText = getCssTextObjToString(cssText);
        preloadHtml.innerText = "Double click to edit";

        document.querySelector("section.preload-wrap").append(preloadHtml);
      }
    }
  });
};

const addMergedObjects = rowsArray => {
  rowsArray.forEach((row, rowIndex) => {
    for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
      const cell = row.cells[cellIndex];

      // Handle colspan > 1
      if (cell.colspan > 1) {
        for (let c = 1; c < cell.colspan; c++) {
          row.cells.splice(cellIndex + c, 0, { merged: true, value: "", mergedId: `body-${rowIndex}-${cellIndex}` });
        }
      }

      // Handle rowspan > 1
      if (cell.rowspan > 1) {
        for (let r = 1; r < cell.rowspan; r++) {
          const targetRowIndex = rowIndex + r;

          if (rowsArray[targetRowIndex]) {
            const targetRow = rowsArray[targetRowIndex];

            // Ensure placeholders align correctly by adding at the appropriate position
            const position = targetRow.cells.findIndex(
              (_, idx) => idx >= cellIndex && targetRow.cells[idx]?.merged !== true
            );

            targetRow.cells.splice(position >= 0 ? position : cellIndex, 0, {
              merged: true,
              value: "",
              mergedId: `body-${rowIndex}-${cellIndex}`,
            });
            // Handle colspan > 1
            if (cell.colspan > 1) {
              for (let c = 1; c < cell.colspan; c++) {
                targetRow.cells.splice(cellIndex + c, 0, {
                  merged: true,
                  value: "",
                  mergedId: `body-${rowIndex}-${cellIndex}`,
                });
              }
            }
          }
        }
      }
    }
  });

  return rowsArray;
};

const generateTableObj = widgetId => {
  let tableObj = {};
  let widgetHtmlContent = document.querySelector(`#${widgetId}`);
  let widgetVersion = widgetHtmlContent.dataset.version;

  if (widgetVersion === "2.0") {
    // get total head column
    let tableHeaderDOM = widgetHtmlContent.getElementsByTagName("thead")[0];
    let tableBodyDOM = widgetHtmlContent.getElementsByTagName("tbody")[0];
    let tableFooterDOM = widgetHtmlContent.getElementsByTagName("tfoot")[0];
    tableObj["rows"] = tableBodyDOM.children.length;
    tableObj["columns"] = tableHeaderDOM.rows[0].children.length;
    let headerStyle = tableHeaderDOM?.rows[0]?.children[0]?.children[0].style;
    let footerStyle = tableFooterDOM?.rows[0]?.children[0]?.children[0].style;
    tableObj["thead"] = {
      backgroundColor: RGBToHex(tableHeaderDOM.style.backgroundColor),
      fontFamily: headerStyle.fontFamily.replaceAll('"', ""),
      fontSize: parseInt(headerStyle.fontSize),
      color: RGBToHex(headerStyle.color),
      fontWeight: headerStyle.fontWeight,
      textDecoration: headerStyle.textDecoration,
      textAlign: tableHeaderDOM.style.textAlign,
      fontStyle: headerStyle.fontStyle,
      bold: headerStyle.fontWeight > 400,
      italic: headerStyle.fontStyle === "italic",
    };

    let singleBodyCell = tableBodyDOM.children[0]?.childNodes[0]?.childNodes[0].style;

    let defaultTableData = {};
    let columns = [];
    let footer = [];

    // fetch Table Header value
    let columnCount = 0;
    Object.entries(tableHeaderDOM?.rows[0]?.children).forEach(elem => {
      const isOldTable = elem[1]?.childNodes[0].hasAttribute("contenteditable");
      let colSpan = isOldTable ? 1 : elem[1].getAttribute("colspan");
      columnCount += parseInt(colSpan);
      for (let i = 1; i <= parseInt(colSpan); i++) {
        let value = "";
        if (i === 1)
          value = isOldTable ? elem[1]?.childNodes[0].innerHTML : elem[1]?.childNodes[0].childNodes[0].innerHTML;
        let colValueObj = {
          value: value,
          width: elem[1].style.width,
          colspan: parseInt(colSpan),
        };
        if (elem[1]?.childNodes[0].classList[0]) {
          colValueObj["alignClass"] = elem[1].childNodes[0].classList[0];
        }
        if (i > 1) {
          colValueObj["merged"] = true;
          colValueObj["width"] = "100px";
          delete colValueObj.colspan;
        }
        columns.push(colValueObj);
      }
    });
    tableObj["columns"] = columnCount;
    defaultTableData["columns"] = columns;

    const tableRows = [...widgetHtmlContent.querySelectorAll("tbody > tr")];
    const tableData = {
      rows: [],
    };
    tableRows.forEach(row => {
      const cells = [...row.children];
      const rowData = {
        cells: [],
      };

      cells.forEach(cell => {
        const isOldTable = cell.childNodes[0].hasAttribute("contenteditable");
        const cellData = {
          value: isOldTable ? cell.childNodes[0].innerHTML : cell.childNodes[0].childNodes[0].innerHTML,
          colspan: isOldTable ? 1 : parseInt(cell.colSpan),
          rowspan: isOldTable ? 1 : parseInt(cell.rowSpan),
        };
        if (cell.childNodes[0].classList[0]) {
          cellData["alignClass"] = cell.childNodes[0].classList[0];
        }
        rowData.cells.push(cellData);
      });

      tableData.rows.push(rowData);
    });

    // Modify rows based on colspan and rowspan
    let formattedRows = addMergedObjects(tableData.rows);

    // Handle merged cells
    // tableData.rows.forEach((row, rowIndex) => {
    //   row.cells.forEach((cell, cellIndex) => {
    //     if (cell.rowspan > 1 || cell.colspan > 1) {
    //       for (let i = 0; i < cell.rowspan; i++) {
    //         for (let j = 0; j < cell.colspan; j++) {
    //           if (i === 0 && j > 0) {
    //             // Update for same rowIndex but next cellIndex
    //             row.cells.splice(cellIndex + j, 0, {
    //               merged: true,
    //               value: "",
    //               mergedId: `body-${rowIndex}-${cellIndex}`,
    //             });
    //           } else if (i > 0) {
    //             // update for same rowIndex but same cellIndex
    //             const mergedRow = tableData.rows[rowIndex + i];
    //             mergedRow?.cells?.splice(cellIndex + j, 0, {
    //               merged: true,
    //               value: "",
    //               mergedId: `body-${rowIndex}-${cellIndex}`,
    //             });
    //           }
    //         }
    //       }
    //     }
    //   });
    // });

    // tableData.rows.forEach((row, rowIndex) => {
    //   row.cells.forEach((cell, cellIndex) => {
    //     if (cell.rowspan > 1) {
    //       for (let i = 1; i < cell.rowspan; i++) {
    //         const mergedRow = tableData.rows[rowIndex + i];
    //         mergedRow?.cells?.splice(cellIndex, 0, { merged: true, value: "" });
    //       }
    //     }
    //     if (cell.colspan > 1) {
    //       for (let i = 1; i < cell.colspan; i++) {
    //         row.cells.splice(cellIndex + i, 0, { merged: true, value: "" });
    //       }
    //     }
    //     for (let k = row.cells.length + 1; k <= tableObj["columns"]; k++) {
    //       row.cells.splice(k, 0, { merged: true, value: "" });
    //     }
    //   });
    // });

    defaultTableData["rows"] = formattedRows;

    // Fetch table footer value
    defaultTableData["footer"] = [];
    if (tableFooterDOM) {
      let footerCount = 0;
      Object.entries(tableFooterDOM?.rows[0].children).forEach(elem => {
        let colSpan = elem[1].getAttribute("colspan") || 1;
        footerCount += parseInt(colSpan);

        for (let i = 1; i <= parseInt(colSpan); i++) {
          let value = "";
          if (i === 1) value = elem[1]?.childNodes[0].childNodes[0].innerHTML;
          let colValueObj = {
            value: value,
            colspan: parseInt(colSpan),
          };
          if (elem[1]?.childNodes[0].classList[0]) {
            colValueObj["alignClass"] = elem[1].childNodes[0].classList[0];
          }
          if (i > 1) colValueObj["merged"] = true;
          footer.push(colValueObj);
        }
      });

      tableObj["footerEnabled"] = true;
      defaultTableData["footer"] = footer;

      let footerBackground = tableFooterDOM?.rows[0]?.children[0]?.style;
      // Table footer style fetch
      tableObj["tfoot"] = {
        backgroundColor: RGBToHex(footerBackground.backgroundColor),
        fontFamily: footerStyle.fontFamily.replaceAll('"', ""),
        fontSize: parseInt(footerStyle.fontSize),
        color: RGBToHex(footerStyle.color),
        fontWeight: footerStyle.fontWeight,
        textDecoration: footerStyle.textDecoration,
        textAlign: tableFooterDOM.children[0].style.textAlign,
        fontStyle: footerStyle.fontStyle,
        bold: footerStyle.fontWeight > 400,
        italic: footerStyle.fontStyle === "italic",
      };
    }

    tableObj["defaultTableData"] = defaultTableData;

    tableObj["tbody"] = {
      backgroundColorOdd: RGBToHex(tableBodyDOM.children[0]?.childNodes[0].childNodes[0].style.backgroundColor),
      fontFamily: singleBodyCell.fontFamily.replaceAll('"', ""),
      fontSize: parseInt(singleBodyCell.fontSize),
      color: RGBToHex(singleBodyCell.color),
      fontWeight: singleBodyCell.fontWeight,
      textDecoration: singleBodyCell.textDecoration,
      textAlign: tableBodyDOM.style.textAlign,
      colorScheme: widgetHtmlContent.getAttribute("data-color-scheme"),
      fontStyle: singleBodyCell.fontStyle,
      bold: singleBodyCell.fontWeight > 400,
      italic: singleBodyCell.fontStyle === "italic",
    };

    if (widgetHtmlContent.getAttribute("data-color-scheme") === "alternate") {
      tableObj["tbody"].backgroundColorEven = RGBToHex(
        tableBodyDOM.children[1]?.childNodes[0].childNodes[0].style.backgroundColor
      );
    }

    tableObj["border"] = {
      horizontal: tableBodyDOM.children[0]?.childNodes[0].style.borderBottomStyle,
      vertical: tableBodyDOM.children[0]?.childNodes[0].style.borderLeftStyle,
      width: parseInt(tableBodyDOM.children[0]?.childNodes[0].style.borderLeftWidth),
      color: RGBToHex(tableBodyDOM.children[0]?.childNodes[0].style.borderLeftColor),
    };
  }

  if (widgetVersion === "1.0") {
    const table = widgetHtmlContent.querySelector("table");
    const thead = table.querySelector("thead");
    const tbody = table.querySelector("tbody");

    const theadChilds = {
      tr: thead.querySelector("tr"),
      th: thead.querySelector("tr th"),
      div: thead.querySelector("tr th div"),
      span: thead.querySelector("tr th div span"),
    };

    const tbodyChilds = {
      tr: tbody.querySelector("tr"),
      td: tbody.querySelector("tr td"),
      div: tbody.querySelector("tr td div"),
    };

    tableObj["rows"] = tbody.querySelectorAll("tr").length;
    tableObj["columns"] = thead.querySelectorAll("tr th").length;

    tableObj["thead"] = {
      backgroundColor: RGBToHex(theadChilds.th.style.backgroundColor),
      fontFamily: theadChilds.span.style.fontFamily.replaceAll('"', ""),
      fontSize: parseInt(theadChilds.span.style.fontSize),
      color: RGBToHex(theadChilds.span.style.color),
      fontWeight: theadChilds.span.style.fontWeight,
      textDecoration: theadChilds.span.style.textDecoration,
      textAlign: theadChilds.th.style.textAlign,
      fontStyle: theadChilds.span.style.fontStyle,
      bold: theadChilds.span.style.fontWeight > 400,
      italic: theadChilds.span.style.fontStyle === "italic",
    };

    tableObj["defaultTableData"] = {
      columns: [...thead.querySelectorAll("tr th")].map(th => {
        const span = th.querySelector("div span");
        return {
          value: span.innerHTML,
          width: th.style.width,
        };
      }),
      rows: [...tbody.querySelectorAll("tr")].map(tr => {
        return {
          cells: [...tr.querySelectorAll("td div")].map(div => div.innerHTML),
        };
      }),
    };

    tableObj["tbody"] = {
      backgroundColorOdd: RGBToHex(tbodyChilds.td.style.backgroundColor),
      fontFamily: tbodyChilds.div.style.fontFamily.replaceAll('"', ""),
      fontSize: parseInt(tbodyChilds.div.style.fontSize),
      color: RGBToHex(tbodyChilds.div.style.color),
      fontWeight: tbodyChilds.div.style.fontWeight,
      textDecoration: tbodyChilds.div.style.textDecoration,
      textAlign: tbodyChilds.td.style.textAlign,
      colorScheme: widgetHtmlContent.dataset.colorScheme,
      fontStyle: tbodyChilds.div.style.fontStyle,
      bold: tbodyChilds.div.style.fontWeight > 400,
      italic: tbodyChilds.div.style.fontStyle === "italic",
    };

    if (widgetHtmlContent.dataset.colorScheme === "alternate") {
      tableObj["tbody"]["backgroundColorEven"] = RGBToHex(
        tbody.querySelectorAll("tr")[1].querySelector("td").style.backgroundColor
      );
    }

    tableObj["border"] = {
      horizontal: tbodyChilds.td.style.borderBottomStyle,
      vertical: tbodyChilds.td.style.borderLeftStyle,
      width: parseInt(tbodyChilds.td.style.borderLeftWidth),
      color: RGBToHex(tbodyChilds.td.style.borderLeftColor),
    };
  }
  return tableObj;
};

const getParentByTag = (elem, lookingFor) => {
  let parent;
  if (elem === null || lookingFor === "") return;
  parent = elem.parentNode;
  lookingFor = lookingFor.toUpperCase();

  while (parent.tagName !== "HTML") {
    if (parent.tagName === lookingFor) {
      return parent;
    }
    parent = parent.parentNode;
  }

  return parent;
};

const checkClipboardObj = () => {
  let clipboardText = localStorage.getItem("ClipboardData"); //Get local storage data
  let isclipBoardObj = getStringToValidJSON(clipboardText);

  if (isclipBoardObj) {
    let clipboardObject = JSON.parse(clipboardText);
    return clipboardObject?.companyId;
  } else return false;
};

const stringTocamelCase = str => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

const getWidgetAndParentsDomReference = widgetId => {
  const widget = document.getElementById(widgetId);
  const block = widget?.closest(".dhp-page-block");
  const page = widget?.closest(".dhp-page-canvas");
  return {
    widget: {
      id: widgetId,
      node: widget,
      css: widget?.style,
      data: widget?.dataset,
      rect: widget?.getBoundingClientRect(),
      isLocked: widget?.classList.contains("draggable-disabled") || widget?.dataset?.layerLocked === "true",
      isGroupSelected: widget?.classList.contains("group-selected"),
      isSingleSelected: widget?.classList.contains("single-selected"),
      isGroupWidget: widget?.classList.contains("dhp-page-group") || widget?.dataset?.assetType === GROUP_WIDGET,
      isChildWidget: widget?.closest(".dhp-page-group.dhp-root-widget") ? true : false,
      parent: {
        id: widget?.closest(".dhp-page-group.dhp-root-widget")?.id,
        node: widget?.closest(".dhp-page-group.dhp-root-widget"),
      },
    },
    block: {
      id: block?.getAttribute("id"),
      idx: parseInt(block?.getAttribute("data-block-idx")),
      node: block,
      css: block?.style,
      data: block?.dataset,
      rect: block?.getBoundingClientRect(),
    },
    page: {
      id: page?.getAttribute("id"),
      idx: parseInt(page?.getAttribute("data-page-idx")),
      node: page,
      css: page?.style,
      data: page?.dataset,
      rect: page?.getBoundingClientRect(),
    },
    editorOuterWrapTop: document.querySelectorAll(".dhp-page-canvas")[0]?.getBoundingClientRect()?.top,
  };
};

const widgetQuery = widgetId => {
  const data = widgetId;
  const dataType = typeof widgetId;
  let returnObj = { isInactiveWidget: false, isSingleWidget: false, isMultiWidget: false, data: false };

  // no widget selected
  if ((dataType === "boolean" && !data) || (dataType === "object" && Array.isArray(data) && data.length === 0)) {
    return { ...returnObj, isInactiveWidget: true };
  }

  // single widget selected
  else if ((dataType === "string" && data) || (dataType === "object" && Array.isArray(data) && data.length === 1)) {
    const domRef = getWidgetAndParentsDomReference(data[0]);

    if (domRef?.widget?.node) {
      return { ...returnObj, isSingleWidget: true, data: domRef };
    } else {
      return { ...returnObj, isInactiveWidget: true };
    }
  }

  // multi widget selected
  else if (dataType === "object" && Array.isArray(data) && data.length >= 2) {
    return { ...returnObj, isMultiWidget: true, data };
  }
};

const uuid4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const setTargetRedirectionEndpoint = uri => {
  // Remove all previous dh-target session
  for (let key in window.sessionStorage) {
    // Check if the key contains the specified pattern
    if (key.indexOf("dh-target-") === 0) {
      // Remove the item from session storage
      window.sessionStorage.removeItem(key);
    }
  }

  uri = uri.replace(process.env.BASE_URL, "");
  let hashkey = "dh-target-" + uuid4();
  window.sessionStorage.setItem(hashkey, uri);
  return hashkey;
};

const getTargetRedirectionEndpoint = hashkey => {
  let endpoint = window.sessionStorage.getItem(hashkey);
  window.sessionStorage.removeItem(hashkey);
  return endpoint;
};

const getPixelToPercent = ({ parent, child }) => {
  return (100 / parseFloat(parent)) * parseFloat(child);
};

const getPercentToPixel = ({ parent, child }) => {
  return (parseFloat(parent) / 100) * parseFloat(child);
};

const checkChildOutsideParent = ({ parent, child }) => {
  const parentElem = parent instanceof Element ? parent : document.querySelector(parent);
  const childElem = child instanceof Element ? child : document.querySelector(child);
  const parentPos = parentElem?.getBoundingClientRect();
  const childPos = childElem?.getBoundingClientRect();

  const relativePos = {
    top: childPos.top - parentPos.top,
    left: childPos.left - parentPos.left,
    right: parentPos.width + (childPos.right - parentPos.right),
    bottom: parentPos.height + (childPos.bottom - parentPos.bottom),
  };

  const isOutside =
    relativePos.right <= 0 ||
    relativePos.bottom <= 0 ||
    relativePos.left >= parentPos.width ||
    relativePos.top >= parentPos.height;

  return isOutside;
};

const getGroupUpdatedHeight = (groupId, innerWidgetId, updatedHeight, zoom) => {
  let zoomValue = zoom ?? 100;
  let groupTransform = document.getElementById(groupId).style.transform;
  let grouptranslateValue = getCssTransformObj({ transform: groupTransform, exclude: ["rotate", "scale"] });
  let groupTop = getZoomedValue(parseFloat(grouptranslateValue.translate.y), zoomValue);

  let ht = updatedHeight;
  let tp =
    getZoomedValue(parseFloat(window.getComputedStyle(document.getElementById(innerWidgetId)).top), zoomValue) +
    groupTop;
  let bt = tp + ht;

  document.querySelectorAll(`#${groupId} .dhp-page-widget`).forEach(element => {
    let widgetId = element.getAttribute("id");
    let top = getZoomedValue(parseFloat(window.getComputedStyle(element).top), zoomValue) + groupTop;
    let height = widgetId == innerWidgetId ? updatedHeight : parseFloat(window.getComputedStyle(element).height);
    let bottom = top + height;

    bt = bt > bottom ? bt : bottom;
  });

  let updatedGroupHeight = bt - groupTop;

  return updatedGroupHeight;
};

const getUpdatedGroupInnerWidgetPos = (groupId, innerWidgetId, updatedHeight, zoom) => {
  let groupDivHeight = getGroupUpdatedHeight(groupId, innerWidgetId, updatedHeight, zoom);
  let widPrevTop = parseFloat(window.getComputedStyle(document.getElementById(innerWidgetId)).top);
  let zoomValue = zoom ?? 100;

  let parcentHeight =
    getPixelToPercent({
      parent: getUnScaledValue(groupDivHeight, zoomValue),
      child: getUnScaledValue(updatedHeight, zoomValue),
    }) + "%";

  let parcentTop =
    getPixelToPercent({
      parent: getUnScaledValue(groupDivHeight, zoomValue),
      child: widPrevTop,
    }) + "%";

  return { groupDivHeight, parcentHeight, parcentTop };
};

const durationInSec = value => {
  if (!value) return;
  const arr = value.split(":");
  return parseFloat(arr[0]) * 60 + parseFloat(arr[1]);
};

// Handle Smart Resize START
const generateSmartResizedWidgets = (
  decreseFactor,
  shiftX,
  shiftY,
  newBlockHeight,
  newBlockWidth,
  widgets,
  canvasZoom,
  blockId,
  setTextFontSize
) => {
  let allWidgets;
  if (blockId)
    allWidgets = Object.assign(
      [],
      widgets.filter(wz => wz.blockId === blockId)
    );
  else allWidgets = Object.assign([], widgets);

  let innerWidgetUpdated = false;
  let widgetStyle;
  allWidgets = allWidgets.map(wz => {
    let curWidget = document.querySelector(`#${wz.id}`);
    let curWidgetInner = curWidget?.querySelector(`.dhp-widget-inner`);
    if (!curWidgetInner) return false; // eliminate widget if not in DOM
    let {
      widget: {
        rect: {
          left: widgetLeft,
          top: widgetTop,
          right: widgetRight,
          bottom: widgetBottom,
          width: widgetWidth,
          height: widgetHeight,
        },
      },
      block: {
        rect: { left: blockLeft, top: blockTop, right: blockRight, bottom: blockBottom },
      },
    } = getWidgetAndParentsDomReference(wz.id);

    widgetHeight = getUnScaledValue(widgetHeight, canvasZoom);
    widgetWidth = getUnScaledValue(widgetWidth, canvasZoom);

    if (
      widgetLeft <= blockLeft &&
      widgetTop <= blockTop &&
      widgetRight >= blockRight &&
      widgetBottom >= blockBottom &&
      [PICTURE, VIDEO, UPLOAD].includes(curWidget.dataset.assetType) &&
      SVG !== curWidget.dataset.fileType &&
      curWidget.dataset.source !== YOUTUBE &&
      !curWidgetInner.dataset.clipPath
    ) {
      widgetHeight = curWidgetInner.childNodes[0].getBoundingClientRect().height;
      widgetWidth = curWidgetInner.childNodes[0].getBoundingClientRect().width;
      let decreseFactor = Math.max(
        (parseFloat(newBlockHeight) + 20) / widgetHeight,
        (parseFloat(newBlockWidth) + 20) / widgetWidth
      );
      let curFlipable = document.querySelector(`#${wz.id} .flippable`);
      let shiftY = (parseFloat(newBlockHeight) - widgetHeight * decreseFactor) / 2;
      let shiftX = (parseFloat(newBlockWidth) - widgetWidth * decreseFactor) / 2;
      let newHeight = `${widgetHeight * decreseFactor}px`;
      let newWidth = `${widgetWidth * decreseFactor}px`;
      curWidgetInner.style.height = newHeight;
      curWidgetInner.style.width = newWidth;
      curFlipable.style.width = newWidth;
      curFlipable.style.height = newHeight;
      curFlipable.style.overflow = "hidden";
      curWidgetInner.style.transform = "translate(0px, 0px) scale(1, 1)";
      curWidgetInner.dataset.crop = JSON.stringify({ n: 0, s: 0, w: 0, e: 0 });
      innerWidgetUpdated = true;
      widgetStyle = generateBGWidgetStyle(wz, newHeight, newWidth, shiftX, shiftY);
    } else {
      widgetStyle = generateWidgetStyle(wz, decreseFactor, shiftX, shiftY);

      // FOR SHAPE RESIZE
      if (
        curWidget.dataset.assetType === SHAPE &&
        curWidget.dataset.category === "Rectangles" &&
        curWidget.dataset.scheme === "filled"
      ) {
        applyFilledRectangleShapeSmartResize({ widgetId: wz.id, resizedData: widgetStyle });
      }

      // For Text Widget
      if (curWidget.dataset.assetType === TEXT) {
        let initialData = {
          fontSize: parseFloat(curWidgetInner.style.fontSize),
          width: parseFloat(curWidgetInner.style.width),
        };
        applyTextSmartResize({ widgetInnerRef: curWidgetInner, decreseFactor, initialData, setTextFontSize });
        innerWidgetUpdated = true;
      }

      // For 8 Handler Widget
      if (
        [PICTURE, VIDEO, UPLOAD].includes(curWidget.dataset.assetType) &&
        SVG !== curWidget.dataset.fileType &&
        curWidget.dataset.source !== YOUTUBE &&
        !curWidgetInner.dataset.clipPath
      ) {
        let initialData = {
          height: parseFloat(curWidgetInner.style.height),
          width: parseFloat(curWidgetInner.style.width),
          transform: curWidgetInner.style.transform,
          crop: getStringToValidJSON(curWidgetInner.dataset.crop) ?? { n: 0, s: 0, w: 0, e: 0 },
        };
        applyEightHandlerSmartResize({ widgetInnerRef: curWidgetInner, decreseFactor, initialData });
        innerWidgetUpdated = true;
      }

      // For crop-to-shape Widget
      if ([PICTURE, VIDEO, UPLOAD].includes(curWidget.dataset.assetType) && curWidgetInner.dataset.clipPath) {
        let {
          scale: { x: widgetInnerOldScale },
        } = getCssTransformObj({
          transform: curWidgetInner.style.transform,
        });
        let initialData = { widgetInnerOldScale, width: parseFloat(curWidgetInner.style.width) };
        applyCropToShapeSmartResize({ widgetInnerRef: curWidgetInner, initialData, currentData: widgetStyle });
        innerWidgetUpdated = true;
      }

      //  For Table and Map Widget
      if ([TABLE, MAP].includes(curWidget.dataset.assetType)) {
        let initialData = { height: curWidgetInner.clientHeight, width: curWidgetInner.clientWidth };
        applyTableANDMapSmartResize({ widgetInnerRef: curWidgetInner, initialData, currentData: widgetStyle });
        innerWidgetUpdated = true;
      }

      //  For Line Widget
      if (curWidget.dataset.assetType === LINE) {
        let lineThickness = parseInt(parseInt(curWidgetInner.dataset.linethickness) * decreseFactor);
        lineThickness = lineThickness > 0 ? lineThickness : 1;
        let applicableHeight = applyLineSmartResize({
          widgetObj: curWidget,
          lineThickness,
          widgetId: wz.id,
          currentData: widgetStyle,
        });
        widgetStyle.height = `${applicableHeight}px`;
        innerWidgetUpdated = true;
      }

      //  For GROUP Widget
      if (curWidget.dataset.assetType === GROUP_WIDGET) {
        let initialValues = {
          childInitials: getChildWidgetInitials(wz.id),
          height: getZoomedValue(widgetHeight, canvasZoom),
        };
        let resizedData = { height: parseFloat(widgetStyle.height), width: parseFloat(widgetStyle.width) };
        applyGroupWidgetSmartResize({
          widgetId: wz.id,
          initialValues,
          canvasZoom,
          resizedData,
          decreseFactor,
          setTextFontSize,
        });
        innerWidgetUpdated = true;
      }

      // For Collage Widget
      if (curWidget.dataset.assetType === COLLAGE) {
        applyCollageSmartResize.start({ widgetInnerRef: curWidgetInner, currentData: widgetStyle });
        innerWidgetUpdated = true;
      }
    }

    if (!innerWidgetUpdated)
      return {
        ...wz,
        style: { ...widgetStyle },
        data: {
          ...wz.data,
          "data-x-allignment": "false",
          "data-y-allignment": "false",
        },
      };
    else {
      return {
        ...wz,
        style: { ...widgetStyle },
        data: {
          ...wz.data,
          "data-x-allignment": "false",
          "data-y-allignment": "false",
        },
        innerHTML: document.getElementById(wz.id).innerHTML,
      };
    }
  });
  return allWidgets;
};

const generateWidgetStyle = (wz, decreseFactor, shiftX, shiftY) => {
  let widgetTransform = wz.style.transform;
  let widgettranslateValue = getCssTransformObj({ transform: widgetTransform, exclude: ["rotate", "scale"] });
  let widgetNewLeft = parseFloat(widgettranslateValue.translate.x) * decreseFactor;
  let widgetNewTop = parseFloat(widgettranslateValue.translate.y) * decreseFactor;

  let widgetTransformString = getCssTransformObj({
    transform: widgetTransform,
    translateX: `${widgetNewLeft + shiftX}px`,
    translateY: `${widgetNewTop + shiftY}px`,
    returnStr: true,
  });

  let widgetStyle = {
    ...wz.style,
    width: `${parseFloat(wz.style.width) * decreseFactor}px`,
    height: `${parseFloat(wz.style.height) * decreseFactor}px`,
    transform: widgetTransformString,
  };
  return widgetStyle;
};

const generateBGWidgetStyle = (wz, height, width, x, y) => {
  let widgetTransform = wz.style.transform;

  let widgetTransformString = getCssTransformObj({
    transform: widgetTransform,
    translateX: `${x}px`,
    translateY: `${y}px`,
    returnStr: true,
  });

  let widgetStyle = {
    ...wz.style,
    width,
    height,
    transform: widgetTransformString,
  };
  return widgetStyle;
};

const applyFilledRectangleShapeSmartResize = ({ widgetId, decreseFactor, initialData, resizedData }) => {
  return;

  let newHeight = resizedData ? parseFloat(resizedData.height) : parseFloat(initialData.height) * decreseFactor;
  let newWidth = resizedData ? parseFloat(resizedData.width) : parseFloat(initialData.width) * decreseFactor;
  const svgElem = document.querySelector(`#${widgetId} svg`);
  svgElem.style.verticalAlign = "top";

  document.querySelectorAll(`#${widgetId} svg *`).forEach(node => {
    if (node.tagName === "rect") {
      node.setAttribute("width", newWidth + "px");
      node.setAttribute("height", newHeight + "px");
      svgElem.setAttribute("viewBox", `0 0 ${newWidth} ${newHeight}`);
    }
  });
};

const applyTextSmartResize = ({ widgetInnerRef, decreseFactor, initialData, setTextFontSize }) => {
  let fontSize = initialData.fontSize * decreseFactor;
  fontSize = fontSize < 100 ? fontSize.toFixed(1) : Math.round(fontSize);
  widgetInnerRef.style.fontSize = `${fontSize}px`;
  widgetInnerRef.style.width = `${(initialData.width * decreseFactor).toFixed(2)}px`;
  widgetInnerRef.style.letterSpacing = `${(initialData.letterSpacing * decreseFactor).toFixed(2)}px`;

  // Update text tollbar font size value
  if (
    widgetInnerRef?.parentElement?.classList?.contains("child-selected") &&
    document.getElementById("textFontSizeDropdown")
  )
    document.getElementById("textFontSizeDropdown").value = `${fontSize}`;
  setTextFontSize(fontSize);
};

const applyEightHandlerSmartResize = ({ widgetInnerRef, decreseFactor, initialData }) => {
  let newHeight = `${parseFloat(initialData.height) * decreseFactor}px`;
  let newWidth = `${parseFloat(initialData.width) * decreseFactor}px`;
  let widgetInnerTranslateValue = getCssTransformObj({
    transform: initialData.transform,
    exclude: ["rotate", "scale"],
  });
  let widgetNewLeft = parseFloat(widgetInnerTranslateValue.translate.x) * decreseFactor;
  let widgetNewTop = parseFloat(widgetInnerTranslateValue.translate.y) * decreseFactor;

  let widgetTransformString = getCssTransformObj({
    transform: initialData.transform,
    translateX: `${widgetNewLeft}px`,
    translateY: `${widgetNewTop}px`,
    returnStr: true,
  });

  widgetInnerRef.style.height = newHeight;
  widgetInnerRef.style.width = newWidth;
  widgetInnerRef.style.transform = widgetTransformString;

  widgetInnerRef.parentNode.style.width = `${
    (initialData.flipableWidth ?? parseFloat(widgetInnerRef.parentNode.style.width)) * decreseFactor
  }px`;
  widgetInnerRef.parentNode.style.height = `${
    (initialData.flipableHeight ?? parseFloat(widgetInnerRef.parentNode.style.height)) * decreseFactor
  }px`;
  widgetInnerRef.parentNode.style.overflow = "hidden";
  let cropData = initialData.crop;
  let newCropData = {
    n: cropData.n * decreseFactor,
    s: cropData.s * decreseFactor,
    w: cropData.w * decreseFactor,
    e: cropData.e * decreseFactor,
  };
  widgetInnerRef.dataset.crop = JSON.stringify(newCropData);
};

const applyCropToShapeSmartResize = ({ widgetInnerRef, initialData, currentData }) => {
  let widgetInnerScale = parseFloat(currentData.width) / parseFloat(initialData.width);
  const transformStr = getCssTransformObj({
    scaleX: widgetInnerScale,
    scaleY: widgetInnerScale,
    transform: widgetInnerRef.style.transform,
    exclude: ["rotate"],
    returnStr: true,
  });
  widgetInnerRef.style.transform = transformStr;
  // applying new height width to flipable div
  widgetInnerRef.parentNode.style.height = `${CROP_TO_SHAPE_HEIGHT * widgetInnerScale}px`;
  widgetInnerRef.parentNode.style.width = `${CROP_TO_SHAPE_WIDTH * widgetInnerScale}px`;
};

const applyTableANDMapSmartResize = ({ widgetInnerRef, initialData, currentData }) => {
  let newScale = parseFloat(currentData.width) / initialData.width;
  widgetInnerRef.style.transform = `scale(${newScale})`;
  widgetInnerRef.style.width = `${initialData.width}px`; // On add table widget-inner width missing
  widgetInnerRef.style.height = `${initialData.height}px`; // On add table widget-inner height missing
};

const applyLineSmartResize = ({ widgetObj, lineThickness, widgetId, currentData }) => {
  let viewBox = false,
    arrowRightDimension = false;
  let targetElem = widgetObj.querySelector("svg");
  let dataCategory = widgetObj.dataset.category;
  let viewBoxHeightFactor = targetElem.getAttribute("data-viewbox-height-factor");
  let applicableHeight = viewBoxHeightFactor ? lineThickness * eval(viewBoxHeightFactor) : lineThickness;

  targetElem.setAttribute("data-linethickness", lineThickness);

  if (dataCategory === BASIC && targetElem.getAttribute("arrow-head-direction") !== "none") {
    arrowRightDimension = calculateArrowDimensions(widgetId);
    viewBox = calculateViewBoxDimensions(widgetObj);
  }

  // =======  START: SVG manipulation   ======= //

  // Set linethickness in svg onject and Draw svg line patterns
  if (dataCategory === DECORATED || (dataCategory === BASIC && targetElem.getAttribute("line-type") !== "solid")) {
    drawSvgLinePattern(widgetObj, targetElem, lineThickness, currentData.width, widgetId);
  }

  // Apply height, width and scale on svg as per resizing
  // for decorated lines and basic lines without arrow heads (solid/dotted/dashed)
  if (
    dataCategory === DECORATED ||
    (dataCategory === BASIC && targetElem.getAttribute("arrow-head-direction") === "none")
  ) {
    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (node.getAttribute("dh-asset-properties")) {
        let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");

        thisChildNodeAttr.forEach(prop => {
          if (prop === "height") {
            if (dataCategory === "Basic") {
              if (targetElem.getAttribute("line-type") && targetElem.getAttribute("line-type") === "solid") {
                node.setAttribute("height", lineThickness + "px");
              } else {
                let applicableHeight = lineThickness < 5 ? 5 : lineThickness;
                node.setAttribute("height", applicableHeight + "px");
              }
            } else if (dataCategory === "Decorated") {
              let applicableHeight = lineThickness < 20 ? 20 : lineThickness;
              node.setAttribute("height", applicableHeight + "px");
            }
          }
          if (prop === "width") {
            node.setAttribute("width", currentData.width);
          }
          if (prop === "transform-scale") {
            let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
            node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
          }
        });
      }
    });

    targetElem.setAttribute("viewBox", `0 0 ${parseFloat(currentData.width)} ${lineThickness}`);
  }
  // for basic lines with arrow heads (solid/dotted/dashed)
  else if (dataCategory === BASIC && targetElem.getAttribute("arrow-head-direction") !== "none") {
    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (
        node.getAttribute("arrow-head") &&
        (node.getAttribute("arrow-head") === "right" || node.getAttribute("arrow-head") === "left")
      ) {
        if (node.getAttribute("dh-asset-properties")) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");

          thisChildNodeAttr.forEach(prop => {
            if (prop === "transform-scale") {
              let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
              node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
              arrowRightDimension = calculateArrowDimensions(widgetId);

              if (node.getAttribute("arrow-head") === "right" || node.getAttribute("arrow-head") === "left") {
                targetElem.setAttribute("viewBox", `0 0 ${viewBox.width} ${arrowRightDimension.height}`);
                viewBox = calculateViewBoxDimensions(widgetObj);
              }
            }
          });
        }
      }
    });

    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (
        node.getAttribute("arrow-head-svg") &&
        (node.getAttribute("arrow-head-svg") === "right" || node.getAttribute("arrow-head-svg") === "left")
      ) {
        if (node.getAttribute("dh-asset-properties") !== undefined) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");

          thisChildNodeAttr.forEach(prop => {
            if (prop === "x") {
              if (node.getAttribute("arrow-head-svg") === "right") {
                let translateX = viewBox.width - arrowRightDimension.width;
                node.setAttribute("x", translateX);
              }
              if (node.getAttribute("arrow-head-svg") === "left") {
                let translateX = 0;
                node.setAttribute("x", translateX);
              }
            }
            if (prop === "y") {
              if (node.getAttribute("arrow-head-svg") === "right") {
                let translateYMiddlePercent = 0;
                node.setAttribute("y", translateYMiddlePercent + "%");
              }
              if (node.getAttribute("arrow-head-svg") === "left") {
                let translateYMiddlePercent =
                  (100 / viewBox.height) * ((viewBox.height - arrowRightDimension.height) / 2);
                node.setAttribute("y", translateYMiddlePercent + "%");
              }
            }
          });
        }
      }
    });

    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (
        (node.getAttribute("line-svg") && node.getAttribute("line-svg") === "true") ||
        (node.getAttribute("solid-line-object") && node.getAttribute("solid-line-object") === "true")
      ) {
        if (node.getAttribute("dh-asset-properties") !== undefined) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");

          thisChildNodeAttr.forEach(prop => {
            if (prop === "height") {
              if (targetElem.getAttribute("line-type") && targetElem.getAttribute("line-type") === "solid") {
                node.setAttribute("height", lineThickness + "px");
              } else {
                let applicableHeight = lineThickness < 5 ? 5 : lineThickness;
                node.setAttribute("height", applicableHeight + "px");
              }
            }
            if (prop === "view-box-height") {
              let applicableHeight = viewBox.height < 5 ? 5 : viewBox.height;
              node.setAttribute("height", applicableHeight + "px");
            }
            if (prop === "width") {
              if (targetElem.getAttribute("arrow-head-direction") === "right") {
                let width = viewBox.width - arrowRightDimension.width / 4;
                width = width < 0 ? 0 : width;
                node.setAttribute("width", width + "px");
              }
              if (targetElem.getAttribute("arrow-head-direction") === "left-right") {
                let width = viewBox.width - (arrowRightDimension.width / 4 + arrowRightDimension.width / 4);
                width = width < 0 ? 0 : width;
                node.setAttribute("width", width + "px");
              }
            }
            if (prop === "x") {
              if (targetElem.getAttribute("arrow-head-direction") === "left-right") {
                let translateX = arrowRightDimension.width / 4;
                node.setAttribute("x", translateX);
              }
            }
            if (prop === "y") {
              if (
                targetElem.getAttribute("arrow-head-direction") === "right" ||
                targetElem.getAttribute("arrow-head-direction") === "left-right"
              ) {
                let translateYMiddlePercent =
                  (100 / viewBox.height) * ((arrowRightDimension.height - lineThickness) / 2);
                node.setAttribute("y", translateYMiddlePercent + "%");
              }
            }
          });
        }
      }
      if (node.getAttribute("line-object") && node.getAttribute("line-object") === "true") {
        if (node.getAttribute("dh-asset-properties") !== undefined) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");
          thisChildNodeAttr.forEach(prop => {
            if (prop === "transform-scale") {
              let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
              node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
            }
            if (prop === "transform-scale-translate") {
              let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
              let translateYMiddlePercent = eval(node.getAttribute("data-transform-translate-const-y"));
              node.setAttribute(
                "transform",
                `scale(${lineThickness * scaleFactor}) translate(0, ${translateYMiddlePercent})`
              );
            }
          });
        }
      }
    });

    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (
        (node.getAttribute("line-svg") && node.getAttribute("line-svg") === "true") ||
        (node.getAttribute("solid-line-object") && node.getAttribute("solid-line-object") === "true")
      ) {
        if (node.getAttribute("dh-asset-properties") !== undefined) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");
          thisChildNodeAttr.forEach(prop => {
            if (prop === "height") {
              if (targetElem.getAttribute("line-type") && targetElem.getAttribute("line-type") === "solid") {
                node.setAttribute("height", lineThickness + "px");
              } else {
                let applicableHeight = lineThickness < 5 ? 5 : lineThickness;
                node.setAttribute("height", applicableHeight + "px");
              }
            }
            if (prop === "view-box-height") {
              let applicableHeight = viewBox.height < 5 ? 5 : viewBox.height;
              node.setAttribute("height", applicableHeight + "px");
            }
            if (prop === "width") {
              if (targetElem.getAttribute("arrow-head-direction") === "right") {
                let width = parseFloat(currentData.width) - arrowRightDimension.width / 4;
                width = width < 0 ? 0 : width;
                node.setAttribute("width", width + "px");
                targetElem.setAttribute("viewBox", `0 0 ${parseFloat(currentData.width)} ${viewBox.height}`);
                calculateViewBoxDimensions(widgetObj);
              }
              if (targetElem.getAttribute("arrow-head-direction") === "left-right") {
                let width =
                  parseFloat(currentData.width) - (arrowRightDimension.width / 4 + arrowRightDimension.width / 4);
                width = width < 0 ? 0 : width;
                node.setAttribute("width", width + "px");
                targetElem.setAttribute("viewBox", `0 0 ${parseFloat(currentData.width)} ${viewBox.height}`);
                calculateViewBoxDimensions(widgetObj);
              }
            }
            if (prop === "y") {
              if (
                targetElem.getAttribute("arrow-head-direction") === "right" ||
                targetElem.getAttribute("arrow-head-direction") === "left-right"
              ) {
                let translateYMiddlePercent =
                  (100 / viewBox.height) * ((arrowRightDimension.height - lineThickness) / 2);
                node.setAttribute("y", translateYMiddlePercent + "%");
              }
            }
          });
        }
      }
      if (node.getAttribute("line-object") && node.getAttribute("line-object") === "true") {
        if (node.getAttribute("dh-asset-properties") !== undefined) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");
          thisChildNodeAttr.forEach(prop => {
            if (prop === "transform-scale") {
              let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
              node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
            }
            if (prop === "transform-scale-translate") {
              let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
              let translateYMiddlePercent = eval(node.getAttribute("data-transform-translate-const-y"));
              node.setAttribute(
                "transform",
                `scale(${lineThickness * scaleFactor}) translate(0, ${translateYMiddlePercent})`
              );
            }
          });
        }
      }
    });

    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (
        node.getAttribute("arrow-head-svg") &&
        (node.getAttribute("arrow-head-svg") === "right" || node.getAttribute("arrow-head-svg") === "left")
      ) {
        if (node.getAttribute("dh-asset-properties") !== undefined) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");
          thisChildNodeAttr.forEach(prop => {
            if (prop === "x") {
              if (node.getAttribute("arrow-head-svg") === "right") {
                let translateX = parseFloat(currentData.width) - arrowRightDimension.width;
                node.setAttribute("x", translateX);
              }
              if (node.getAttribute("arrow-head-svg") === "left") {
                let translateX = 0;
                node.setAttribute("x", translateX);
              }
            }
            if (prop === "y") {
              if (node.getAttribute("arrow-head-svg") === "right") {
                let translateYMiddlePercent =
                  (100 / viewBox.height) * ((viewBox.height - arrowRightDimension.height) / 2);
                node.setAttribute("y", translateYMiddlePercent + "%");
              }
              if (node.getAttribute("arrow-head-svg") === "left") {
                let translateYMiddlePercent =
                  (100 / viewBox.height) * ((viewBox.height - arrowRightDimension.height) / 2);
                node.setAttribute("y", translateYMiddlePercent + "%");
              }
            }
          });
        }
      }
    });
  }
  // =======  END: SVG manipulation   ======= //
  return applicableHeight;
};

const applyCollageSmartResize = {
  getCollageNodeAndDataRef: ({ widgetId, collageChild }) => {
    if (widgetId) {
      const widget = document.getElementById(widgetId);
      const collageContainer = widget.querySelector(".collage-container");

      return {
        collage: widget.querySelector(".collage"),
        mainContent: widget.querySelector(".main-content"),
        collageContainer,
        collageItems: widget.querySelectorAll(".collage-item"),
        activeCollageItem: widget.querySelector(".collage-item.active"),
        gridGap: parseInt(collageContainer.style.gap),
        widgetProps: {
          widgetWidth: parseFloat(widget.style.width),
          widgetHeight: parseFloat(widget.style.height),
        },
      };
    }

    if (collageChild) {
      const mediaGrandParent = collageChild.querySelector(".media-grand-parent");
      const mediaParent = collageChild.querySelector(".media-parent");
      const mediaNode = collageChild.querySelector(".media-node");
      const cropFrame = collageChild.querySelector(".crop-frame");
      const { width: mgpW = 1, height: mgpH = 1 } = mediaGrandParent.style;
      const { width: mpW = 1, height: mpH = 1, transform: mpTransform = "" } = mediaParent.style;
      const { translate: { x: mpTransX = "", y: mpTransY = "" } = {} } = getCssTransformObj({
        transform: mpTransform,
      });

      return {
        mediaGrandParent,
        mediaParent,
        mediaNode,
        cropFrame,
        mediaProps: {
          frameWidth: parseFloat(mgpW),
          frameHeight: parseFloat(mgpH),
          pictureWidth: parseFloat(mpW),
          pictureHeight: parseFloat(mpH),
          pictureTransX: parseFloat(mpTransX),
          pictureTransY: parseFloat(mpTransY),
          pictureTransform: mpTransform,
          pictureAr: parseFloat(mpW) / parseFloat(mpH),
          cropData: JSON.parse(mediaParent.getAttribute("data-crop")),
        },
      };
    }
  },

  generateScaledDim: ({ wrapperDimOld, wrapperDimNew, pictureWidth, pictureHeight, pictureAr, cropData }) => {
    let scaledWidth, scaledHeight;

    if (pictureWidth > wrapperDimNew.w && pictureHeight > wrapperDimNew.h) {
      if (pictureWidth - cropData.w > wrapperDimNew.w || pictureWidth - cropData.e > wrapperDimNew.w) {
        scaledWidth = pictureWidth;
        scaledHeight = pictureHeight;
      } else {
        const factor = pictureWidth / wrapperDimOld.w;
        scaledWidth = wrapperDimNew.w * factor;
        scaledHeight = scaledWidth / pictureAr;
      }
    } else {
      const factor = Math.max(wrapperDimNew.w / pictureWidth, wrapperDimNew.h / pictureHeight);
      scaledWidth = pictureWidth * factor;
      scaledHeight = pictureHeight * factor;
    }

    return { scaledWidth, scaledHeight };
  },

  generateCropData: ({ wrapperDimNew, scaledWidth, scaledHeight, cropData }) => {
    let translateX,
      translateY,
      dataCrop = { n: 0, s: 0, w: 0, e: 0 };

    if (cropData.w === cropData.e) {
      translateX = (wrapperDimNew.w - scaledWidth) / 2;
      dataCrop = {
        ...dataCrop,
        w: Math.abs(translateX),
        e: Math.abs(translateX),
      };
    } else {
      const overflown_width_old = cropData.w + cropData.e;
      const overflown_width_new = wrapperDimNew.w - scaledWidth;
      const crop_factor_w = cropData.w / overflown_width_old;
      const crop_factor_e = cropData.e / overflown_width_old;

      translateX = overflown_width_new * crop_factor_w;
      dataCrop = {
        ...dataCrop,
        w: Math.abs(overflown_width_new * crop_factor_w),
        e: Math.abs(overflown_width_new * crop_factor_e),
      };
    }

    if (cropData.n === cropData.s) {
      translateY = (wrapperDimNew.h - scaledHeight) / 2;
      dataCrop = { ...dataCrop, n: Math.abs(translateY), s: Math.abs(translateY) };
    } else {
      const overflown_height_old = cropData.n + cropData.s;
      const overflown_height_new = wrapperDimNew.h - scaledHeight;
      const crop_factor_n = cropData.n / overflown_height_old;
      const crop_factor_s = cropData.s / overflown_height_old;

      translateY = overflown_height_new * crop_factor_n;
      dataCrop = {
        ...dataCrop,
        n: Math.abs(overflown_height_new * crop_factor_n),
        s: Math.abs(overflown_height_new * crop_factor_s),
      };
    }

    return { translateX, translateY, dataCrop: JSON.stringify(dataCrop) };
  },

  start: ({ widgetInnerRef, currentData }) => {
    const widgetWidth = parseFloat(currentData.width);
    const widgetHeight = parseFloat(currentData.height);
    const widgetNode = widgetInnerRef.closest(".dhp-root-widget");
    const { mainContent, collageItems, gridGap } = applyCollageSmartResize.getCollageNodeAndDataRef({
      widgetId: widgetNode.id,
    });

    mainContent.style.minHeight = `${widgetHeight}px`;

    collageItems.forEach(item => {
      const mediaGrandParent = item.querySelector(".media-grand-parent");

      if (mediaGrandParent) {
        // setting wrapper dimension
        const {
          wf = null,
          hf = null,
          gfw = null,
          gfh = null,
        } = getStringToValidJSON(item.dataset.props) ?? { wf: null, hf: null, gfw: null, gfh: null };

        if ([wf, hf, gfw, gfh].includes(null)) return;

        const wrapperDimOld = {
          w: parseFloat(mediaGrandParent.style.width),
          h: parseFloat(mediaGrandParent.style.height),
        };

        const wrapperDimNew = {
          w: widgetWidth * parseFloat(wf) - gridGap * parseFloat(gfw),
          h: widgetHeight * parseFloat(hf) - gridGap * parseFloat(gfh),
        };

        mediaGrandParent.style.width = wrapperDimNew.w + "px";
        mediaGrandParent.style.height = wrapperDimNew.h + "px";

        // setting media dimension
        const {
          mediaParent,
          mediaProps: { pictureWidth, pictureHeight, pictureAr, cropData },
        } = applyCollageSmartResize.getCollageNodeAndDataRef({
          collageChild: item,
        });

        const { scaledWidth, scaledHeight } = applyCollageSmartResize.generateScaledDim({
          wrapperDimOld,
          wrapperDimNew,
          pictureWidth,
          pictureHeight,
          pictureAr,
          cropData,
        });

        const { translateX, translateY, dataCrop } = applyCollageSmartResize.generateCropData({
          wrapperDimNew,
          scaledWidth,
          scaledHeight,
          cropData,
        });

        mediaParent.style.width = scaledWidth + "px";
        mediaParent.style.height = scaledHeight + "px";
        mediaParent.style.transform = `translate(${translateX}px, ${translateY}px) scale(1, 1)`;
        mediaParent.setAttribute("data-crop", dataCrop);
      }
    });
  },
};

const calculateViewBoxDimensions = widgetObj => {
  return {
    width: parseFloat(widgetObj.querySelector("svg")?.viewBox?.baseVal?.width),
    height: parseFloat(widgetObj.querySelector("svg")?.viewBox?.baseVal?.height),
  };
};

const calculateArrowDimensions = widgetId => {
  let bbox;

  document.querySelectorAll(`#${widgetId} svg *`)?.forEach(node => {
    if (
      node.getAttribute("arrow-head-svg") &&
      (node.getAttribute("arrow-head-svg") === "right" || node.getAttribute("arrow-head-svg") === "left")
    ) {
      bbox = node.getBBox();
    }
  });
  return bbox;
};

const drawSvgLinePattern = (widgetObj, svgObj, lineThickness, widgetWidth, widgetId) => {
  widgetWidth = widgetWidth ? widgetWidth : widgetObj.style.width;

  if (
    widgetObj &&
    widgetObj.getAttribute("data-version") !== undefined &&
    widgetObj.getAttribute("data-version") === "2.0"
  ) {
    let patternCloneX = parseFloat(svgObj.getAttribute("data-pattern-clone-x"));
    let patternCloneXVsLinethicknessRatio = eval(svgObj.getAttribute("data-pattern-clone-x-vs-linethickness-ratio"));
    let requiredPatterns = Math.ceil(parseFloat(widgetWidth) / (lineThickness * patternCloneXVsLinethicknessRatio));
    let patternWrapper = false;
    let patternMaster = false;
    let patternCount = false;

    document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
      if (node.getAttribute("line-object") && node.getAttribute("line-object") === "true") {
        patternWrapper = node;
        patternCount = node.childElementCount;
      }
      if (node.getAttribute("group-type") && node.getAttribute("group-type") === "pattern-master") {
        patternMaster = node;
      }
    });

    if (patternMaster) {
      if (requiredPatterns > patternCount) {
        for (let i = patternCount; i < requiredPatterns; i++) {
          let nextPattern = patternMaster.cloneNode(true);
          nextPattern.setAttribute("group-type", `pattern-clone-${i}`);
          nextPattern.setAttribute("x", i * patternCloneX);

          patternWrapper.append(nextPattern);
        }
      }
      if (requiredPatterns < patternCount) {
        for (let i = requiredPatterns; i < patternCount; i++) {
          document.querySelectorAll(`#${widgetId} svg *`).forEach(function (node) {
            if (node.getAttribute("group-type") && node.getAttribute("group-type") === `pattern-clone-${i}`) {
              node.remove();
            }
          });
        }
      }
    }
  }
};

const getChildWidgetInitials = widgetId => {
  let childInitials = [],
    initalValues;
  document.querySelectorAll(`#${widgetId} .dhp-page-widget`).forEach(childWidget => {
    let childId = childWidget.getAttribute("id");
    let innerWidget = childWidget.querySelector(".dhp-widget-inner");

    //  FOR GROUPPED FILLED RECTANGLE SHAPE
    if (
      childWidget.dataset.assetType === SHAPE &&
      childWidget.dataset.category === "Rectangles" &&
      childWidget.dataset.scheme === "filled"
    ) {
      initalValues = { childId, width: `${childWidget.clientWidth}px`, height: `${childWidget.clientHeight}px` };
      childInitials = [...childInitials, initalValues];
    }

    // FOR GROUPPED TEXT
    if (childWidget.dataset.assetType === TEXT) {
      initalValues = {
        childId,
        fontSize: parseFloat(innerWidget.style.fontSize),
        width: parseFloat(innerWidget.style.width),
        letterSpacing: parseFloat(innerWidget.style.letterSpacing),
      };
      childInitials = [...childInitials, initalValues];
    }

    // FOR GROUPPED 8 HANDLER WIDGET
    if (
      [PICTURE, VIDEO, UPLOAD].includes(childWidget.dataset.assetType) &&
      SVG !== childWidget.dataset.fileType &&
      childWidget.dataset.source !== YOUTUBE &&
      !innerWidget.dataset.clipPath
    ) {
      let curFlipable = childWidget.querySelector(".flippable");
      initalValues = {
        childId,
        height: parseFloat(innerWidget.style.height),
        width: parseFloat(innerWidget.style.width),
        flipableHeight: parseFloat(curFlipable.style.height),
        flipableWidth: parseFloat(curFlipable.style.width),
        transform: innerWidget.style.transform,
        crop: getStringToValidJSON(innerWidget.dataset.crop) ?? { n: 0, s: 0, w: 0, e: 0 },
      };
      childInitials = [...childInitials, initalValues];
    }

    // FOR GROUPPED CROP-TO-SHAPE
    if ([PICTURE, VIDEO, UPLOAD].includes(childWidget.dataset.assetType) && innerWidget.dataset.clipPath) {
      let {
        scale: { x: widgetInnerOldScale },
      } = getCssTransformObj({
        transform: innerWidget.style.transform,
      });
      initalValues = { childId, width: parseFloat(innerWidget.style.width), widgetInnerOldScale };
      childInitials = [...childInitials, initalValues];
    }

    //  FOR GROUPPED TABLE AND MAP
    if ([TABLE, MAP].includes(childWidget.dataset.assetType)) {
      initalValues = { childId, height: innerWidget.clientHeight, width: innerWidget.clientWidth };
      childInitials = [...childInitials, initalValues];
    }

    //  FOR GROUPPED LINE
    if (childWidget.dataset.assetType === LINE) {
      let lineThickness = parseInt(innerWidget.dataset.linethickness) ?? 1;
      initalValues = {
        childId,
        lineThickness,
        height: innerWidget.clientHeight,
        width: `${innerWidget.clientWidth}px`,
      };
      childInitials = [...childInitials, initalValues];
    }
  });
  return childInitials;
};

const applyGroupWidgetSmartResize = ({
  widgetId,
  initialValues,
  canvasZoom,
  resizedData,
  decreseFactor = 1,
  setTextFontSize,
}) => {
  let resizeFactor = resizedData.height / getUnScaledValue(initialValues.height, canvasZoom);
  document.querySelectorAll(`#${widgetId} .dhp-page-widget`).forEach(childWidget => {
    let childId = childWidget.getAttribute("id");
    let initialData = initialValues.childInitials.find(rec => rec.childId === childId);
    let widgetInnerRef = childWidget.querySelector(".dhp-widget-inner");

    // FOR GROUPPED FILLED RECTANGLE SHAPE RESIZE
    if (
      childWidget.dataset.assetType === SHAPE &&
      childWidget.dataset.category === "Rectangles" &&
      childWidget.dataset.scheme === "filled"
    )
      applyFilledRectangleShapeSmartResize({ widgetId: childId, decreseFactor: resizeFactor, initialData });

    // FOR GROUPPED TEXT RESIZE
    if (childWidget.dataset.assetType === TEXT)
      applyTextSmartResize({ widgetInnerRef, decreseFactor: resizeFactor, initialData, setTextFontSize });

    // FOR GROUPPED 8 HANDLER WIDGET RESIZE
    if (
      [PICTURE, VIDEO, UPLOAD].includes(childWidget.dataset.assetType) &&
      SVG !== childWidget.dataset.fileType &&
      childWidget.dataset.source !== YOUTUBE &&
      !widgetInnerRef.dataset.clipPath
    )
      applyEightHandlerSmartResize({ widgetInnerRef, decreseFactor: resizeFactor, initialData });

    // FOR GROUPPED CROP-TO-SHAPE RESIZE
    if ([PICTURE, VIDEO, UPLOAD].includes(childWidget.dataset.assetType) && widgetInnerRef.dataset.clipPath) {
      let currentData = { width: parseFloat(window.getComputedStyle(childWidget).width) * decreseFactor };
      applyCropToShapeSmartResize({ widgetInnerRef, initialData, currentData });
    }

    //  FOR GROUPPED TABLE AND MAP RESIZE
    if ([TABLE, MAP].includes(childWidget.dataset.assetType)) {
      let currentData = { width: parseFloat(window.getComputedStyle(childWidget).width) * decreseFactor };
      applyTableANDMapSmartResize({ widgetInnerRef, initialData, currentData });
    }

    //  FOR GROUPPED LINE RESIZE
    if (childWidget.dataset.assetType === LINE) {
      let lineThickness = initialData.lineThickness * resizeFactor;
      lineThickness = lineThickness > 0 ? lineThickness : 1;
      let currentData = { width: `${parseFloat(window.getComputedStyle(childWidget).width) * decreseFactor}px` };
      applyLineSmartResize({ widgetObj: childWidget, lineThickness, widgetId: childId, currentData });
    }
  });
};
// Handle Smart Resize END

const replaceStrAt = (str, index, char) => {
  let a = str.split("");
  a[index] = char;
  return a.join("");
};

const setGMTAtSession = timezone => {
  let gmtValue = timezone.split(/\(([^()]*)\)/g)?.[1];
  if (!["+", "-"].includes(gmtValue.charAt(3))) {
    gmtValue = replaceStrAt(gmtValue, 3, "+");
  }
  window.sessionStorage.setItem("_dh_gmtvalue", gmtValue);
  return gmtValue;
};

const calculateNewZoomValue = (pageWidth, pageHeight, isTimeLineViewOpen = false, isVideoTypeUser) => {
  // using isTimeLineViewOpen for video ediotor only
  const topBottomMergin = isVideoTypeUser ? PAGE_MARGIN_TOP_BOTTOM : PAGE_MARGIN_TOP_BOTTOM_NON_VIDEO_TYPE_USER;
  let adjustablePaddingValue = isTimeLineViewOpen ? PAGE_MARGIN_TOP_BOTTOM_WITH_TIMELINE : topBottomMergin;
  let canvasPanelArea = document.getElementById("canvas-panel-area");
  // If "canvas-panel-area" is not ready yet, use static padding values.
  // Using top: 30px, right: 40px, bottom: 30px, left: 51px, which are equivalent to the values in _editor.scss:
  // padding: 1.875rem 2.5rem 1.875rem 3.188rem. (1rem = 16px)
  let canvasPannelareaPaddingLeft = canvasPanelArea
    ? parseFloat(window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-left"))
    : 51;

  let canvasPannelareaPaddingRight = canvasPanelArea
    ? parseFloat(window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-right"))
    : 40;

  let canvasPannelareaPaddingTop = canvasPanelArea
    ? parseFloat(window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-top"))
    : 30;

  let canvasPannelareaPaddingBottom = canvasPanelArea
    ? parseFloat(window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-bottom"))
    : 30;

  let canvasWidth =
    (canvasPanelArea ? canvasPanelArea.offsetWidth : document.querySelector(".editor-wrapper").clientWidth) -
    (canvasPannelareaPaddingLeft + canvasPannelareaPaddingRight);
  let canvasHeight =
    (canvasPanelArea ? canvasPanelArea.offsetHeight : document.querySelector(".editor-wrapper").clientHeight) -
    (canvasPannelareaPaddingTop + canvasPannelareaPaddingBottom);

  let zoomval = parseFloat(
    Math.min((canvasWidth - PAGE_MARGIN_LEFT_RIGHT) / pageWidth, (canvasHeight - adjustablePaddingValue) / pageHeight) *
      100
  );
  zoomval = zoomval > 250 ? 250 : zoomval;
  return zoomval;
};

const clipboard = {
  hasPermissions: async types => {
    let permissions = [];
    for (let type of types) {
      permissions.push(await clipboard.hasPermission(type));
    }
    return !permissions.some(permission => !permission);
  },

  hasPermission: async type => {
    if (!["read", "write"].includes(type)) return;
    try {
      await (type === "read" ? navigator.clipboard.readText() : navigator.clipboard.writeText(type));
      return true;
    } catch {
      return;
    }
  },

  read: async () => {
    try {
      const clipText = await navigator.clipboard.readText();
      if (clipText.length > 0) {
        const clipObj = getStringToValidJSON(clipText);
        return {
          dataProvider: "navigator.clipboard",
          dataSource: clipObj?.companyId && clipObj?.widgetObj ? "dhp" : "non_dhp",
          dataType: "text/plain",
          data: clipObj ?? clipText,
          isValidWidget: clipObj?.companyId && clipObj?.widgetObj ? true : false,
        };
      } else {
        // When clipboard has mimeTypes apart from "text/plain". Example: "image/png", "text/html", "application/json", ...etc.
        const items = await navigator.clipboard.read();
        for (let item of items) {
          if (item.types.includes("image/png")) {
            const blob = await item.getType("image/png");
            return {
              dataProvider: "navigator.clipboard",
              dataSource: "non_dhp",
              dataType: "image/png",
              data: blob,
              isValidWidget: true,
            };
          } else return;
        }
      }
    } catch {
      return;
    }
  },

  write: async text => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch {
      return;
    }
  },
};

const getWidgetInnerFileType = ({ widgetNode }) => {
  const widgetInnerNode = widgetNode.querySelector(".dhp-widget-inner");
  if (!widgetInnerNode) return;

  const typeSvg = widgetNode.querySelector("svg");
  const typeImage = widgetNode.querySelector("img");
  const typeVideo = widgetNode.querySelector("video");
  const typeMedia = typeImage ?? typeVideo;

  if (typeSvg) {
    return {
      node: typeSvg,
      group: "svg",
      type: "svg",
    };
  } else if (typeMedia) {
    if (typeMedia?.src === "") return;

    const fileType = getFileType(typeMedia?.src);
    if (fileType === "svg" && widgetNode.dataset.widget === "UPLOAD") return;

    return {
      node: typeMedia,
      group: typeImage ? "img" : "video",
      type: fileType,
    };
  } else {
    let dataHeading = widgetNode?.dataset?.heading;

    if (!dataHeading) {
      const tagName = widgetInnerNode?.tagName?.toLowerCase();
      dataHeading = tagName === "div" ? "normal" : tagName;
    }

    return {
      node: widgetInnerNode,
      group: "text",
      type: dataHeading,
    };
  }
};

const removeWhiteSpaceBetweenTags = htmlStr => {
  return htmlStr.replace(/>\s+</g, "><").trim();
};

const removeDuplicateFromArray = arr => {
  return arr.filter((item, idx) => arr.indexOf(item) === idx);
};

const convertToCSV = objArray => {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

const exportToCSV = (headers, formattedRecords, filename) => {
  if (headers) {
    formattedRecords.unshift(headers);
  }
  // Convert Object to JSON
  const jsonObject = JSON.stringify(formattedRecords);
  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = `${filename}.csv` || `export.csv`;

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // for IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const getRandomNumberInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomHexColorCode = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;
};

const getNodeHeightAtRuntime = node => {
  const clone = node.cloneNode(true);
  clone.style.cssText = "position:absolute; top:-9999px; opacity:0;";
  document.body.appendChild(clone);
  const height = clone.offsetHeight;
  clone.parentNode.removeChild(clone);

  return height;
};

const isOnMobileDevice = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true;
  else return false;
};

const convertNonAsciiToClosestAsciiEquivalent = str => {
  const nonAscii = /[\u0300-\u036F]/gim;
  return str.normalize("NFKD").replace(nonAscii, "");
};

const updateHeightAndTopForInactiveGroupChildren = ({
  group,
  groupOldHeight,
  groupNewHeight,
  activeChildId,
  zoom = 100,
}) => {
  const groupWidget = group instanceof Element ? group : document.getElementById(group);
  if (!(groupWidget && groupWidget instanceof Element)) return;

  groupOldHeight = parseFloat(groupOldHeight);
  groupNewHeight = getUnScaledValue(parseFloat(groupNewHeight), zoom);
  const scaleFactor = groupOldHeight / groupNewHeight;

  groupWidget?.childNodes?.forEach(widget => {
    if (widget.id !== activeChildId) {
      const oldTopInPixel = getPercentToPixel({
        parent: parseFloat(groupWidget.style.height),
        child: parseFloat(widget.style.top),
      });
      const oldHeightInPixel = getPercentToPixel({
        parent: parseFloat(groupWidget.style.height),
        child: parseFloat(widget.style.height),
      });
      const newTopInPercent = getPixelToPercent({ parent: groupNewHeight, child: oldTopInPixel * scaleFactor });
      const newHeightInPercent = getPixelToPercent({ parent: groupNewHeight, child: oldHeightInPixel * scaleFactor });

      widget.style.top = newTopInPercent + "%";
      widget.style.height = newHeightInPercent + "%";
    }
  });
};

const isOnlyTextInGroup = (activeWidgetType, id) => {
  let activeGroupId = document.getElementById(id)?.closest(".dhp-root-widget")?.getAttribute("id");
  let isOnlyTextInGroup = true;

  if (activeGroupId) {
    document.getElementById(activeGroupId).childNodes.forEach(element => {
      if (
        element.getAttribute("data-asset-type") !== activeWidgetType ||
        parseInt(element.getAttribute("data-value")) > 0
      ) {
        isOnlyTextInGroup = false;
      }
    });
  }

  return isOnlyTextInGroup;
};

const isUnsuportedWidgetsInSelection = (activeWidgetId, result = false) => {
  let selectedIds = activeWidgetId;
  let count = selectedIds.length;

  if (count === 1) {
    // in case of single selected group widget, if child is selected then forwrding group widget's ID and disable group-ungroup option
    const isChildOfAGroupWidgetSelected = document
      .getElementById(selectedIds[0])
      ?.closest(".dhp-page-group.dhp-root-widget");
    selectedIds = [isChildOfAGroupWidgetSelected?.id] ?? selectedIds;
  }

  for (let i = 0; i < count; i++) {
    const widget = document.getElementById(selectedIds[i]);
    const {
      assetType = null,
      version = 1,
      source = null,
    } = widget?.dataset ?? { assetType: null, version: 1, source: null };
    // restrict video in group
    if (
      ([TABLE, GROUP_WIDGET].includes(assetType) && parseFloat(version) < 2) ||
      [COLLAGE].includes(assetType) ||
      ([STOCK, BRAND, UPLOAD_VIDEO].includes(source) && assetType === VIDEO)
    ) {
      result = true;
      break;
    }
  }

  return result;
};

const colorHexCodeValidation = colorCode => {
  let isHex = /^#([0-9A-F]{6}){1,2}$/i.test(colorCode);
  return isHex;
};

const toggleZoomStyle = ({ type }) => {
  if (type === "revoke") {
    const zoomStyleElement = document.getElementById("zoomStyle");
    if (zoomStyleElement) {
      const zoomStyle = JSON.stringify(zoomStyleElement.innerHTML);
      localStorage.setItem("dhp.zoom.style", zoomStyle);
      zoomStyleElement.remove();
    }
  }
  if (type === "inject") {
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    const zoomStyle = JSON.parse(localStorage.getItem("dhp.zoom.style"));

    setMultiAttrs(style, {
      id: "zoomStyle",
      type: "text/css",
    });
    head.appendChild(style);

    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = zoomStyle;
    } else {
      style.appendChild(document.createTextNode(zoomStyle));
    }

    localStorage.removeItem("dhp.zoom.style");
  }
};

// Decode a Base64 string to an object
const decodeFromBase64 = base64 => {
  const buffer = Buffer.from(base64, "base64");
  const json = buffer.toString();
  const obj = JSON.parse(json);
  return obj;
};

const fetchCookie = key => {
  let coockiedata = Cookies.get(key, { domain: ".dochipo.com" });

  return coockiedata;
};

const removeCookie = key => {
  Cookies.remove(key, { domain: ".dochipo.com" });
};

const convertToSlug = str => {
  if (!str) return;
  return str.toLowerCase().replace(" ", "-");
};

const getKeyBySlug = (data, slug) => {
  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key].slug === slug) {
      return key;
    }
  }
  return null; // Return null if no matching key is found
};

// download file from URL
const fileDownload = (uri, name) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const generateUniqueHash = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36
  const randomPart = Math.random().toString(36).substring(2, 5); // Generate a random part

  // Combine the timestamp and random part to create a unique hash
  const uniqueHash = timestamp + randomPart;

  return uniqueHash;
};

const splitLinerGradientValues = gradValue => {
  let splitArray = gradValue.split(",");
  let gradDeg = parseInt(splitArray[0].split("(")[1]);
  let start = splitArray[1].split(" ");
  let startColor = start[1];
  let startPercent = start[2];
  let end = splitArray[2].split(" ");
  let endColor = end[1];
  let endPercent = `${parseFloat(end[2])}%`;

  return { gradDeg, startColor, startPercent, endColor, endPercent };
};

const createImageFromBase64 = async base64String => {
  // Split the base64 string to get the content type and data
  const parts = base64String.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const rawImageData = window.atob(parts[1]);

  // Create an array of bytes from the rawImageData
  const byteArray = new Uint8Array(new ArrayBuffer(rawImageData.length));
  for (let i = 0; i < rawImageData.length; i++) {
    byteArray[i] = rawImageData.charCodeAt(i);
  }

  // Create a Blob from the byteArray with the specified content type
  const blob = new Blob([byteArray], { type: contentType });

  // Create a File object from the Blob
  const imageFile = new File([blob], "image.png", { type: contentType });
  return imageFile;
};

const shuffle = array => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

// inject user uploaded font into DOM
const injectFonts = fontList => {
  let customFontsStyle = document.getElementById("custom-fonts");
  if (!customFontsStyle) {
    customFontsStyle = document.createElement("style");
    customFontsStyle.type = "text/css";
    customFontsStyle.id = "custom-fonts";
    document.head.appendChild(customFontsStyle);
  }

  const sheet = customFontsStyle.sheet;

  const fontExists = fontName => {
    return Array.from(sheet.cssRules).some(
      rule =>
        rule instanceof CSSFontFaceRule &&
        rule.style.getPropertyValue("font-family").replace(/['"\s]/g, "") === fontName.replace(/['"\s]/g, "")
    );
  };

  fontList.forEach(font => {
    if (!fontExists(font.name)) {
      const ruleText = `
        @font-face {
          font-family: '${font.name}';
          src: url('${font.url.woff2}') format("woff2"),
               url('${font.url.woff}') format("woff"),
               url('${font.url.ttf}') format("truetype");
        }
      `;

      sheet.insertRule(ruleText, sheet.cssRules.length);
      loadCustomFontsDOM([font]);
    }
  });
};

const loadCustomFontsDOM = fonts => {
  // load style to DOM
  fonts.forEach(font => {
    let cssText;
    if (font.name && font.style && font.normal_weight) {
      cssText = {
        "font-family": font.name,
        "font-weight": font.normal_weight,
        "font-style": font.style,
      };

      let preloadHtml = document.createElement("span");
      preloadHtml.style.cssText = getCssTextObjToString(cssText);
      preloadHtml.innerText = "Double click to edit";

      document.querySelector("section.preload-wrap").append(preloadHtml);
    }
  });
};

const sortedUniqueFonts = fonts => {
  // Filter duplicates based on "name" and "normal_weight" property
  const uniqueFonts = Object.assign(fonts)
    .filter(
      (font, index, self) =>
        index === self.findIndex(f => f.name === font.name && f.normal_weight === font.normal_weight)
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  localStorage.setItem("allFonts", JSON.stringify(uniqueFonts));
};

const softDeleteFonts = deletedIds => {
  let allFonts = JSON.parse(localStorage?.getItem("allFonts"));
  const updatedFonts = allFonts.map(obj => (deletedIds.includes(obj.id) ? { ...obj, enabled: false } : obj));
  localStorage.setItem("allFonts", JSON.stringify(updatedFonts));
};

const templateLoaderSize = {
  key: "dhp.temp.loader.thumb.size",

  getData: () => {
    return getStringToValidJSON(localStorage.getItem(templateLoaderSize.key)) ?? {};
  },

  setData: updatedItems => {
    localStorage.setItem(templateLoaderSize.key, JSON.stringify(updatedItems));
  },

  get: ({ typeId }) => {
    const oldItems = templateLoaderSize.getData();
    const queryItem = oldItems[typeId];
    return window.innerWidth === queryItem?.windowWidth ? queryItem : { thumbWidth: null, thumbHeight: null };
  },

  set: ({ typeId, thumbWidth, thumbHeight }) => {
    const oldItems = templateLoaderSize.getData();
    const newItem = {
      [typeId]: {
        thumbWidth,
        thumbHeight,
        windowWidth: window.innerWidth,
      },
    };
    templateLoaderSize.setData({
      ...oldItems,
      ...newItem,
    });
  },
};

function interpolateColor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  var result = color1?.slice();
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

function getInterpolateColors(color1, color2, steps) {
  let stepFactor = 1 / (steps - 1),
    interpolatedColorArray = [];

  color1 = color1?.match(/\d+/g).map(Number);
  color2 = color2?.match(/\d+/g).map(Number);

  for (var i = 0; i < steps; i++) {
    interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
  }

  return interpolatedColorArray;
}

const shortNumberIntToString = num => {
  let precision = 2;
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
};

const getDimentionInCurrentUOM = (dataInPx, uom = "px") => {
  return uom === "px"
    ? parseInt(dataInPx * LENGTH_UNITS_CONVERSION_FACTORS.find(rec => rec.name === uom).factor)
    : parseFloat((dataInPx * LENGTH_UNITS_CONVERSION_FACTORS.find(rec => rec.name === uom).factor).toFixed(2));
};

const calculateNewPositionOnRotatedObjectResize = (left, top, width, height, prevWidth, prevHeight, angle) => {
  // Calculate the center of the widget
  const centerX = left + prevWidth / 2;
  const centerY = top + prevHeight / 2;

  // Calculate the distance from the center to the top-left corner of the widget
  const dx = width / 2 - prevWidth / 2;
  const dy = height / 2 - prevHeight / 2;

  // Apply rotation to the distance
  const angleRad = (angle * Math.PI) / 180;
  const cosTheta = Math.cos(angleRad);
  const sinTheta = Math.sin(angleRad);

  const newX = centerX + (dx * cosTheta - dy * sinTheta);
  const newY = centerY + (dx * sinTheta + dy * cosTheta);

  // Calculate the new position based on the rotated distance
  const newLeft = newX - width / 2;
  const newTop = newY - height / 2;

  return { left: newLeft, top: newTop };
};

const getDocumentDuration = (pages, pageIdx, scaledWidth) => {
  if (parseInt(pages.length) === parseInt(pageIdx)) pageIdx = parseInt(pageIdx) - 1;
  const activePage = pages[pageIdx];
  const durationInSec = {
    activePage: {
      current: parseFloat(activePage.pageDuration),
      available: 0,
      applicable: {
        min: 1,
        max: 180,
      },
      default: {
        min: 1,
        max: 180,
      },
    },
    document: {
      current: 0,
      available: 0,
      newPage: 0,
      default: {
        min: 1,
        max: 1800,
      },
    },
  };
  const { activePage: page, document } = durationInSec;
  pages.forEach(p => (document.current += parseFloat(p.pageDuration)));

  document.available = document.default.max - document.current;
  page.available = page.default.max - page.current;

  // if (document.available > 0 && page.available > 0) {
  //   page.applicable.max = document.available > page.available ? page.available : document.available;
  // }

  page.applicable.max = document.available >= 180 ? 180 : document.available;
  document.newPage = document.available > page.default.max ? page.default.max : document.available;

  // get scaled width (optional)
  if (scaledWidth) {
    page.scaledWidth = {
      min: page.applicable.min * scaledWidth,
      max: page.applicable.max * scaledWidth,
    };
  }

  return durationInSec;
};

const getHMSToSec = ({ hms }) => {
  hms = hms.toString();
  const timeArray = hms.split(":").map(e => parseFloat(e));
  const [hh, mm, ss] = timeArray.length === 2 ? [0, ...timeArray] : timeArray;
  return hh * 360 + mm * 60 + ss;
};

const getSecToHMS = ({ sec, returnStr = "" }) => {
  sec = parseFloat(sec);
  const h = Math.floor(sec / 3600);
  const m = Math.floor(sec / 60) % 60;
  const s = (sec % 60).toFixed(1);

  returnStr += h === 0 ? `` : `${h}:`;
  returnStr += m === 0 ? `0:` : m < 10 ? `0${m}:` : `${m}:`;
  returnStr += parseFloat(s) === 0 ? `00` : parseFloat(s) < 10 ? `0${s}` : `${s}`;

  return returnStr;
};

const isMediaPlaying = ({ mediaElm, startTime, stopTime }) => {
  const isPlaying =
    (startTime && stopTime
      ? mediaElm.currentTime >= startTime && mediaElm.currentTime <= stopTime
      : mediaElm.currentTime > 0) &&
    !mediaElm.paused &&
    !mediaElm.ended &&
    mediaElm.readyState > mediaElm.HAVE_CURRENT_DATA;

  return isPlaying;
};

const playMedia = (mediaElm, startTime, stopTime, t) => {
  let timer = 0;
  const curTime = floatToFloatSec(mediaElm.currentTime);
  if (startTime && stopTime && !(curTime > startTime && curTime < stopTime)) {
    timer = t ?? 100;
    mediaElm.currentTime = startTime;
  }

  setTimeout(() => {
    mediaElm.play().catch(err => {
      console.table(`Media could not be played! possible error => ${err}`);
    });
  }, timer);
};

const floatToFloatSec = floatNumber => {
  return parseFloat(floatNumber.toFixed(1));
};

const getVideoMetadataFromUrl = url => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = url;
    video.onloadedmetadata = () =>
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    video.onerror = reject;
  }).catch(() => {
    return {
      reject: true,
    };
  });
};

const ensureProtocol = url => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const rotatePoint = (x, y, cx, cy, angle) => {
  const radians = (angle * Math.PI) / 180;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) - sin * (y - cy) + cx;
  const ny = sin * (x - cx) + cos * (y - cy) + cy;
  return { x: nx, y: ny };
};

const normalizeAngle = angle => ((parseInt(angle) % 360) + 360) % 360;

const customRound = (num, decimalPlaces = 0) => {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
};

const operateStorageData = ({ type = "default", action, key, isParseable, defaultData }) => {
  const storage = {
    session: sessionStorage ?? window.sessionStorage,
    local: localStorage ?? window.localStorage,
    default: localStorage ?? window.localStorage,
  };
  const srorageType = storage[type];

  if (action === "get") {
    const storageData = srorageType.getItem(key);
    const parsedData = isParseable ? getStringToValidJSON(storageData) : storageData;
    return parsedData ?? defaultData;
  }

  if (action === "set") {
    const storageData = isParseable ? JSON.stringify(defaultData) : defaultData;
    srorageType.setItem(key, storageData);
  }

  if (action === "update") {
    const storageData = srorageType.getItem(key);
    const parsedData = isParseable ? getStringToValidJSON(storageData) : storageData;
    const updatedData = isParseable ? JSON.stringify({ ...parsedData, ...defaultData }) : parsedData ?? defaultData;
    srorageType.setItem(key, updatedData);
  }

  if (action === "remove") {
    srorageType.removeItem(key);
  }
};

const formatTime = timeInSeconds => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = (timeInSeconds % 60).toFixed(1);
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const removeKeyFromStorage = (storageKey) => { 
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.includes(storageKey)) {
      localStorage.removeItem(key);
    }
  }
 }
 
// Preload all map region's images
const loadAllMaps = maps => {
  maps.forEach(mapData => {
    let link = document.createElement("link");
    link.href = mapData.image;
    link.rel = "preload";
    link.as = "image";

    if (!document.querySelector('link[href="' + link.href + '"]'))
      document.getElementsByTagName("head")[0].appendChild(link);
  });
};

const resetEditorMapTooltip = ()=>{
  const tooltipDOM = document.getElementById("editor-map-tooltip");
  tooltipDOM.innerHTML = '<strong class="d-block" id ="areaTitle"></strong><ul id="caption"></ul>';
  tooltipDOM.style.display = "none";
}

export {
  findRouteName,
  reverseLookup,
  getRandomString,
  getUnScaledValue,
  getZoomedValue,
  isClickedOutside,
  placeholderTextAnimation,
  debounceTrailing,
  dateTimeDataset,
  getSiblings,
  preventFormSubmitOnEnter,
  hexToRGB,
  RGBToHex,
  changeWidgetColorAccordingBackground,
  changeBucketColorAccordingBackground,
  getCssTextObjToString,
  getClassListToString,
  getFileType,
  validateNumberInput,
  formatTemplatePageSizes,
  getTimezoneInfo,
  clearAllTextFields,
  getStringToValidJSON,
  stopAllResourceBuffering,
  prepareVideoHtml,
  alignSvg,
  displayAbstractShapes,
  getCssTextObj,
  getCssTransformObj,
  getCssTransformObjToString,
  getActualDomRect,
  setMultiAttrs,
  camelCaseToDash,
  dashToCamelCase,
  dispatchEvent,
  loadAllDBFonts,
  searchNodeByPoint,
  getSvgContentFromUrl,
  getImgDimensionFromUrl,
  generateTableObj,
  getParentByTag,
  checkClipboardObj,
  toProperCase,
  getTemplateInfo,
  imagesLoaded,
  stringTocamelCase,
  getWidgetAndParentsDomReference,
  widgetQuery,
  setTargetRedirectionEndpoint,
  getTargetRedirectionEndpoint,
  getPixelToPercent,
  getPercentToPixel,
  checkChildOutsideParent,
  getUpdatedGroupInnerWidgetPos,
  getGroupUpdatedHeight,
  durationInSec,
  generateSmartResizedWidgets,
  generateWidgetStyle,
  generateBGWidgetStyle,
  applyFilledRectangleShapeSmartResize,
  applyTextSmartResize,
  applyEightHandlerSmartResize,
  applyCropToShapeSmartResize,
  applyTableANDMapSmartResize,
  applyLineSmartResize,
  calculateViewBoxDimensions,
  calculateArrowDimensions,
  drawSvgLinePattern,
  getChildWidgetInitials,
  applyGroupWidgetSmartResize,
  replaceStrAt,
  setGMTAtSession,
  clipboard,
  calculateNewZoomValue,
  getWidgetInnerFileType,
  removeWhiteSpaceBetweenTags,
  removeDuplicateFromArray,
  exportToCSV,
  getRandomNumberInRange,
  getNodeHeightAtRuntime,
  isOnMobileDevice,
  defaultAccountData,
  convertNonAsciiToClosestAsciiEquivalent,
  updateHeightAndTopForInactiveGroupChildren,
  isOnlyTextInGroup,
  isUnsuportedWidgetsInSelection,
  colorHexCodeValidation,
  toggleZoomStyle,
  decodeFromBase64,
  shuffle,
  splitLinerGradientValues,
  fetchCookie,
  removeCookie,
  fileDownload,
  getRandomHexColorCode,
  convertToSlug,
  getKeyBySlug,
  generateUniqueHash,
  createImageFromBase64,
  injectFonts,
  sortedUniqueFonts,
  softDeleteFonts,
  templateLoaderSize,
  getInterpolateColors,
  shortNumberIntToString,
  getDocumentDuration,
  getDimentionInCurrentUOM,
  calculateNewPositionOnRotatedObjectResize,
  getHMSToSec,
  getSecToHMS,
  isMediaPlaying,
  playMedia,
  floatToFloatSec,
  getVideoMetadataFromUrl,
  ensureProtocol,
  rotatePoint,
  normalizeAngle,
  processSVG,
  customRound,
  operateStorageData,
  formatTime,
  removeKeyFromStorage,
  loadAllMaps,
  resetEditorMapTooltip,
};
